import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import "@/pages/FormMenu/index.css";
import { AddEditModal } from "@/components";
import { clearFormDetailsReducer } from "@/reducers/form";
import { createForm, editFormNames, getFormDetails } from "@/services";
import AddressBar from "@/components/Layout/AddressBar";
import { statusData, privacyLevelData, usePageTitle } from "@/helpers";
import { DropDown, Pressable } from "@/components/common";
import AddFormField from "./addFormFields";
import _ from "lodash";
import FormFieldListItem from "./formFieldListItem";

const AddForm = () => {
  const formData = useSelector((state) => {
    return {
      getFormDetailsData: state.form.getFormDetailsData,
      parentCategories: state.equipment.parentCategories,
    };
  });
  const { getFormDetailsData, parentCategories } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({});
  const {
    editID,
    status,
    name = "",
    is_public,
    saveID = [],
    statusField,
    keyName = "",
    isEdit = true,
    savedData = [],
    labelName = "",
    order = "",
    minValue = "",
    maxValue = "",
    prefixValue = "",
    suffixValue = "",
    formElementType,
    required = false,
    description = "",
    placeholder = "",
    formModal = false,
    childFormModal = false,
    optionsData = [],
    rowOptionData = [],
    columnOptionData = [],
    parentValue = "",
    parentId = "",
    mainParentId = "",
    parentValueData,
    options,
    columnOption,
    rowOption,
    isNewFormModal = false,
    isCheckParentValue = false,
    otherDataValueOptions = [],
    otherDisplayValueOptions = [],
    scaleDataError = "",
    data_value,
    display_value,
  } = state;
  const [loading, setLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const formIds = params.get("form-id");

  useEffect(() => {
    if (formIds) {
      dispatch(getFormDetails(formIds)).then((res) => {
        if (res?.data && res?.data?.payload) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      dispatch(clearFormDetailsReducer());
      setLoading(false);
    }
  }, [formIds]);

  usePageTitle(formIds ? "Edit Form" : "Create Form");

  const isMultipleChoiceGrid = ["multiple_choice_grid"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isLinearOption = ["linear"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isCheckBoxRadioOptions = ["checkbox", "radio"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isSelectOption = ["select"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isMultiOptions = ["checkbox", "radio", "select"]?.includes(
    state.formElementType?.toLowerCase()
  );
  const isOther = ["scale"]?.includes(state.formElementType?.toLowerCase());

  const isDisabled = !name || !status || !is_public || !description;
  const isDisabledFormField =
    !labelName ||
    !placeholder ||
    !keyName ||
    !statusField ||
    !formElementType ||
    !order ||
    (isOther &&
      (!otherDisplayValueOptions?.length || !otherDataValueOptions?.length)) ||
    (!isNewFormModal && isCheckParentValue && !parentValue?.length) ||
    (isMultiOptions
      ? !optionsData?.length
      : isMultipleChoiceGrid
      ? !rowOptionData?.length || !columnOptionData?.length
      : null) ||
    (isLinearOption &&
      (!minValue || !maxValue || !prefixValue || !suffixValue));

  useEffect(() => {
    if (!formModal) {
      setState((prev) => ({
        ...prev,
        keyName: "",
        required: "",
        labelName: "",
        placeholder: "",
        minValue: "",
        maxValue: "",
        prefixValue: "",
        suffixValue: "",
        order: "",
        statusField: "",
        formElementType: "",
        parentValue: "",
        optionsData: [],
        rowOptionData: [],
        columnOptionData: [],
        otherDataValueOptions: [],
        otherDisplayValueOptions: [],
        options: "",
        columnOption: "",
        rowOption: "",
        errorMessage: "",
        errorMessageRow: "",
        errorMessageColumn: "",
        scaleDataError: "",
      }));
    }
  }, [formModal]);

  useEffect(() => {
    if (getFormDetailsData) {
      const childElement = (mapElementData) => {
        if (!mapElementData) return [];
        return mapElementData?.map((elementItem) => {
          const multipleJsonData = elementItem?.options
            ? JSON.parse(elementItem?.options)
            : null;
          let optionsData = null;
          let rowOptionData = null;
          let columnOptionData = null;
          let otherDisplayValueOptions = null;
          let otherDataValueOptions = null;
          if (elementItem.type === "select") {
            optionsData = multipleJsonData?.map((option) => option?.label);
          } else if (elementItem.type === "multiple_choice_grid") {
            rowOptionData = multipleJsonData?.rows || [];
            columnOptionData = multipleJsonData?.columns || [];
          } else if (
            elementItem.type === "checkbox" ||
            elementItem.type === "radio"
          ) {
            optionsData = multipleJsonData;
          } else if (elementItem.type === "scale") {
            otherDisplayValueOptions = multipleJsonData?.display_value || [];
            otherDataValueOptions = multipleJsonData?.data_value || [];
          }
          let element = {
            id: elementItem?.id,
            labelName: elementItem?.label,
            keyName: elementItem?.key,
            placeholder: elementItem?.place_holder,
            order: elementItem?.order?.toString(),
            formElementType: elementItem?.type?.toLowerCase(),
            required: elementItem?.required,
            statusField: elementItem?.status?.toLowerCase(),
            optionsData: optionsData,
            rowOptionData: rowOptionData,
            columnOptionData: columnOptionData,
            otherDisplayValueOptions: otherDisplayValueOptions,
            otherDataValueOptions: otherDataValueOptions,
            parent_id: elementItem?.parent_id,
            ...(elementItem?.parent_value
              ? { parent_value: elementItem?.parent_value }
              : {}),
            ...(elementItem?.options
              ? { options: JSON.parse(elementItem?.options) }
              : {}),
            ...(elementItem?.elements?.length
              ? { elements: childElement(elementItem.elements) }
              : {}),
          };
          return element;
        });
      };
      const formElementData = getFormDetailsData?.form_elements?.map((item) => {
        const multipleJsonData = item?.options
          ? JSON.parse(item?.options)
          : null;
        let optionsData = null;
        let rowOptionData = null;
        let columnOptionData = null;
        let otherDisplayValueOptions = null;
        let otherDataValueOptions = null;
        if (item.type === "select") {
          optionsData = multipleJsonData?.map((option) => option?.label);
        } else if (item.type === "multiple_choice_grid") {
          rowOptionData = multipleJsonData?.rows || [];
          columnOptionData = multipleJsonData?.columns || [];
        } else if (item.type === "checkbox" || item.type === "radio") {
          optionsData = multipleJsonData;
        } else if (item.type === "scale") {
          otherDisplayValueOptions = multipleJsonData?.display_value || [];
          otherDataValueOptions = multipleJsonData?.data_value || [];
        } else {
          optionsData = multipleJsonData;
        }
        let elements = {
          id: item?.id,
          keyName: item?.key,
          labelName: item?.label,
          order: item?.order?.toString(),
          statusField: item?.status,
          placeholder: item?.place_holder,
          required: item?.required,
          formElementType: item?.type?.toLowerCase(),
          optionsData: optionsData,
          options: optionsData,
          rowOptionData: rowOptionData,
          columnOptionData: columnOptionData,
          otherDisplayValueOptions: otherDisplayValueOptions,
          otherDataValueOptions: otherDataValueOptions,
          parent_id: item?.parent_id,
          ...(item?.elements?.length
            ? { elements: childElement(item?.elements) }
            : {}),
        };
        return elements;
      });
      setState((prev) => ({
        ...prev,
        saveID: formElementData,
        savedData: formElementData,
        name: getFormDetailsData?.name || "",
        description: getFormDetailsData?.description || "",
        status: getFormDetailsData?.status?.toLowerCase() || "",
        is_public:
          +getFormDetailsData?.is_public === 0
            ? "private"
            : getFormDetailsData?.is_public === 1
            ? "shared"
            : "",
      }));
    }
  }, [getFormDetailsData]);

  useEffect(() => {
    if (savedData) {
      let val = savedData?.sort(
        (sortItemA, sortItemB) => sortItemA?.order - sortItemB?.order
      );
      setState((prev) => ({ ...prev, savedData: val }));
    }
  }, [savedData && savedData?.length, formModal]);

  if (!parentCategories?.includes("form")) {
    return <Navigate to="/dashboard" />;
  }

  const checkAndSetErrorMessage = (errorMessageKey, message) => {
    setState((prev) => ({ ...prev, [errorMessageKey]: message }));
  };

  const handleOnSubmitOptions = (valueKey, dataKey, errorMessageKey) => {
    const convertedArray = state?.[dataKey]?.map((item) =>
      item?.trim().toLowerCase()
    );
    if (!state?.[valueKey]) {
      checkAndSetErrorMessage(errorMessageKey, "Please Enter a value");
    } else if (
      convertedArray.includes(state?.[valueKey].trim().toLowerCase())
    ) {
      checkAndSetErrorMessage(
        errorMessageKey,
        "This Options field is already added."
      );
    } else {
      setState((prev) => ({
        ...prev,
        [valueKey]: "",
        [errorMessageKey]: "",
        [dataKey]: [...state?.[dataKey], state?.[valueKey]],
      }));
    }
  };

  const handleOnPressCancel = (item, key) => {
    const filterOptionsData = state?.[key]?.filter(
      (filterItem) => filterItem !== item
    );
    setState((prev) => ({
      ...prev,
      [key]: filterOptionsData,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let processedValue = value.trimStart();

    if (["order"].includes(name)) {
      processedValue = processedValue.replace(/\D/g, "");
    }

    setState((prev) => ({
      ...prev,
      [name]: processedValue,
      errorMessage: "",
      errorMessageRow: "",
      errorMessageColumn: "",
    }));
  };

  const handleSubmitForm = () => {
    let payload = {
      name: name,
      description: description,
      status: status?.toLowerCase() || "",
      is_public: is_public?.toLowerCase() === "shared",
    };
    if (savedData?.length) {
      const mapElements = (elements) => {
        if (!elements) return [];
        return elements.map((childItem) => ({
          ...(childItem?.newlyCreated ? {} : { id: childItem?.id }),
          key: childItem?.keyName,
          label: childItem?.labelName,
          order: childItem?.order || "",
          place_holder: childItem?.placeholder,
          type: childItem?.formElementType?.toLowerCase(),
          required: childItem?.required || false,
          status: childItem?.statusField?.toLowerCase(),
          ...(childItem?.parentValue
            ? { parent_value: childItem?.parentValue }
            : {}),
          ...(childItem?.options ? { options: childItem?.options } : {}),
          ...(childItem?.elements
            ? { elements: mapElements(childItem?.elements) }
            : {}),
        }));
      };
      const elementData = savedData?.map((item) => {
        let element = {
          ...(item?.parent ? {} : { id: item?.id }),
          key: item?.keyName || "",
          label: item?.labelName || "",
          order: item?.order || "",
          place_holder: item?.placeholder || "",
          required: item?.required || false,
          status: item?.statusField?.toLowerCase() || "",
          type: item?.formElementType?.toLowerCase() || "",
          ...(item?.elements ? { elements: mapElements(item?.elements) } : {}),
        };
        if (item?.formElementType === "select") {
          const options = item?.optionsData?.map((option) => ({
            value: option.toLowerCase(),
            label: option,
          }));
          element.options = options;
        } else if (item?.formElementType === "multiple_choice_grid") {
          const rows = item?.rowOptionData || [];
          const columns = item?.columnOptionData || [];
          element.options = {
            rows,
            columns,
          };
        } else if (
          item?.formElementType === "radio" ||
          item?.formElementType === "checkbox"
        ) {
          element.options = item?.optionsData || [];
        } else if (item?.formElementType === "linear") {
          element.options = {
            min: item?.options?.min,
            max: item?.options?.max,
            prefix: item?.options?.prefix,
            suffix: item?.options?.suffix,
          };
        } else if (item?.formElementType === "scale") {
          const display_value = item?.otherDisplayValueOptions || [];
          const data_value = item?.otherDataValueOptions || [];
          element.options = {
            display_value,
            data_value,
          };
        }
        return element;
      });
      if (elementData.length > 0) {
        payload.elements = elementData;
      }
    }
    if (!getFormDetailsData?.id && !formIds) {
      resposneFunction(dispatch(createForm(payload)), false);
    } else {
      resposneFunction(
        dispatch(editFormNames({ id: getFormDetailsData?.id, data: payload })),
        true
      );
    }
  };

  const resposneFunction = (apiCall, isEditRoute) => {
    setIsSubmitLoading(true);
    apiCall
      .unwrap()
      .then((res) => {
        if (res?.success) {
          if (!isEditRoute) {
            navigate("/forms");
          } else {
            navigate(-1);
          }
          toast.success(res?.message);
          setIsSubmitLoading(false);
        } else {
          toast.error(res?.message);
          setIsSubmitLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message || "Something went wrong!");
        setIsSubmitLoading(false);
      });
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 1000000000);
  };

  const handleFormModal = () => {
    setState((prev) => ({
      ...prev,
      formModal: true,
      childFormModal: false,
      parentId: "",
      isEdit: false,
      isNewFormModal: true,
    }));
  };

  const handleChildSave = (oldData, childData) => {
    const newData = oldData.map((item) => {
      if (item.id === childData.parentId) {
        let newItem = { ...item };
        newItem["elements"] = newItem.elements
          ? [...newItem.elements, childData]
          : [childData];
        return newItem;
      } else {
        if (item?.elements?.length > 0) {
          const updatedElements = handleChildSave(item.elements, childData);
          return { ...item, elements: updatedElements };
        } else {
          return item;
        }
      }
    });
    return newData;
  };

  const checkBoxOptions = state.optionsData?.map((item) => {
    return {
      value: item?.toLowerCase(),
      label: item,
    };
  });

  const editElement = (elements, editedElement) => {
    return elements.map((item) => {
      if (item.id == editedElement.id) {
        return {
          ...item,
          id: item.id,
          keyName: editedElement.keyName,
          required: editedElement.required,
          labelName: editedElement.labelName,
          order: editedElement.order,
          placeholder: editedElement.placeholder,
          statusField: editedElement.statusField?.toLowerCase(),
          parentValue: parentValue,
          optionsData: editedElement.optionsData,
          rowOptionData: editedElement.rowOptionData,
          columnOptionData: editedElement.columnOptionData,
          formElementType: editedElement.formElementType?.toLowerCase(),
          otherDisplayValueOptions: editedElement.otherDisplayValueOptions,
          otherDataValueOptions: editedElement.otherDataValueOptions,
          ...(isLinearOption
            ? {
                options: {
                  min: minValue,
                  max: maxValue,
                  prefix: prefixValue,
                  suffix: suffixValue,
                },
              }
            : {}),
          ...(isMultipleChoiceGrid
            ? {
                options: {
                  rows: editedElement.rowOptionData,
                  columns: editedElement.columnOptionData,
                },
              }
            : {}),
          ...(isCheckBoxRadioOptions
            ? { options: editedElement.optionsData }
            : {}),
          ...(isSelectOption ? { options: checkBoxOptions } : {}),
          ...(isOther
            ? {
                options: {
                  display_value: editedElement.otherDisplayValueOptions,
                  data_value: editedElement.otherDataValueOptions,
                },
              }
            : {}),
        };
      } else if (item.elements) {
        return {
          ...item,
          elements: editElement(item.elements, editedElement),
        };
      }
      return item;
    });
  };

  const handleFormField = () => {
    if (
      formElementType &&
      ["select", "checkbox", "radio"].includes(formElementType) &&
      optionsData?.length < 2
    ) {
      toast.error("Add at least 2 options!");
    } else {
      if (!isEdit) {
        let data = {
          keyName: keyName,
          required: required,
          labelName: labelName,
          order: order,
          placeholder: placeholder,
          id: generateRandomNumber(),
          formElementType: formElementType.toLowerCase() || "",
          parentValue: parentValue,
          parentId: parentId,
          statusField: statusField.toLowerCase() || "",
          optionsData: optionsData,
          rowOptionData: rowOptionData,
          columnOptionData: columnOptionData,
          otherDisplayValueOptions: otherDisplayValueOptions,
          otherDataValueOptions: otherDataValueOptions,
          ...(isLinearOption
            ? {
                options: {
                  min: minValue,
                  max: maxValue,
                  prefix: prefixValue,
                  suffix: suffixValue,
                },
              }
            : {}),
          ...(isMultipleChoiceGrid
            ? {
                options: {
                  rows: rowOptionData,
                  columns: columnOptionData,
                },
              }
            : {}),
          ...(isOther
            ? {
                options: {
                  display_value: otherDisplayValueOptions,
                  data_value: otherDataValueOptions,
                },
              }
            : {}),
          ...(isCheckBoxRadioOptions ? { options: optionsData } : {}),
          ...(isSelectOption ? { options: checkBoxOptions } : {}),
          ...(state.childFormModal ? { newlyCreated: true } : {}),
          parent: true,
          ...(state?.childFormModal
            ? { parent_id: generateRandomNumber() }
            : {}),
        };
        if (state?.childFormModal) {
          const childData = handleChildSave(savedData, data);
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: childData,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: [...savedData, data],
          }));
        }
      } else {
        if (isEdit) {
          const editData = editElement(savedData, {
            id: editID,
            keyName: keyName,
            required: required,
            labelName: labelName,
            order: order,
            placeholder: placeholder,
            optionsData: optionsData,
            parentValue: parentValue,
            statusField: statusField.toLowerCase() || "",
            formElementType: formElementType?.toLowerCase() || "",
            rowOptionData: rowOptionData,
            columnOptionData: columnOptionData,
            otherDisplayValueOptions: otherDisplayValueOptions,
            otherDataValueOptions: otherDataValueOptions,
          });
          setState((prev) => ({
            ...prev,
            formModal: false,
            savedData: editData,
          }));
        }
      }
    }
  };

  const removeElement = (elements, id) => {
    return elements.filter((item) => {
      if (item.id === id) {
        return false;
      } else if (item.elements) {
        item.elements = removeElement(item.elements, id);
        return true;
      } else {
        return true;
      }
    });
  };

  const handleFormFieldDelete = (item) => {
    const updatedFormFieldsList = removeElement(savedData, item?.id);
    setState((prev) => ({ ...prev, savedData: updatedFormFieldsList }));
  };

  const findElementAndParent = (elements, childId, parent = null) => {
    let result = { child: null, parent };
    elements.some((item) => {
      if (item.id === childId) {
        result = { child: item, parent };
        return true;
      } else if (item.elements) {
        const { child, parent: newParent } = findElementAndParent(
          item.elements,
          childId,
          item
        );
        if (child) {
          result = { child, parent: newParent };
          return true;
        }
      }
      return false;
    });
    return result;
  };

  const handleFormFieldUpdate = (item) => {
    const isParent =
      (item.hasOwnProperty("parentId") && item.parentId !== "") ||
      (item.hasOwnProperty("parent_value") && item?.parent_value !== "");
    if (item?.parent_value) {
      const { parent } = findElementAndParent(savedData, item?.id);
      setState((prev) => ({
        ...prev,
        parentValueData: parent?.optionsData,
      }));
    }
    const checkIsArray = Array.isArray(item?.optionsData);
    setState((prev) => ({
      ...prev,
      isEdit: true,
      formModal: true,
      editID: item?.id,
      keyName: item?.keyName,
      required: item?.required,
      labelName: item?.labelName,
      order: item?.order,
      minValue: item?.options?.min,
      maxValue: item?.options?.max,
      prefixValue: item?.options?.prefix,
      suffixValue: item?.options?.suffix,
      placeholder: item?.placeholder,
      formElementType: item?.formElementType || "",
      ...(checkIsArray ? { optionsData: item?.optionsData } : {}),
      parentValue: item?.parentValue || item?.parent_value,
      statusField: item?.statusField.toLowerCase() || "",
      rowOptionData: item?.rowOptionData,
      columnOptionData: item?.columnOptionData,
      isNewFormModal: false,
      isCheckParentValue: isParent,
      otherDisplayValueOptions: item?.otherDisplayValueOptions,
      otherDataValueOptions: item?.otherDataValueOptions,
    }));
  };

  const handleOnChageSelect = (e) => {
    const selectValue = JSON.parse(e?.target?.id);
    setState((prev) => ({
      ...prev,
      [e?.target?.name]: selectValue?.name,
    }));
  };

  const handleChildFormModal = (item) => {
    setState((prev) => ({
      ...prev,
      formModal: true,
      childFormModal: true,
      parentValueData: item?.optionsData,
      parentId: item?.id,
      isEdit: false,
      isNewFormModal: false,
      isCheckParentValue: true,
    }));
  };

  const handleDragEndData = (key, item, otherDataKey, otherItemData) => {
    setState((prev) => ({ ...prev, [key]: item }));
    if (otherDataKey) {
      setState((prev) => ({ ...prev, otherDataValueOptions: otherItemData }));
    }
  };

  const handleDragEndDataForSavedData = (item) => {
    if (item) {
      const changeOrder = item?.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
      setState((prev) => ({ ...prev, savedData: changeOrder }));
    }
  };

  return (
    <>
      <AddressBar
        page={[
          { name: "Forms", link: "/forms" },
          { name: !formIds ? "Add-Form" : "Edit-Form" },
        ]}
      />
      {loading ? (
        <div className="center" style={{ height: window.innerHeight - 250 }}>
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="row p-2 m-0 Bg-fff rounded mt-2 Shadow">
          <Form onSubmit={handleSubmitForm}>
            <Form.Group className="mb-3">
              <Row className="mx-3 gx-5">
                <Col xs={12} sm={12} lg={4} className="mt-4">
                  <label class="itemText lable mt-2 C-dark form-label">
                    Name*
                  </label>
                  <input
                    name="name"
                    value={name}
                    maxLength={100}
                    autocomplete="off"
                    lableclass="mb-0 mt-2"
                    onChange={handleChange}
                    placeholder="Enter Name"
                    className="add-form-input p-2"
                  ></input>
                </Col>
                <Col xs={12} sm={12} lg={4} className="mt-4">
                  <label className="itemText lable mt-2 C-dark form-label">
                    Status*
                  </label>
                  <DropDown
                    noShadow
                    name="status"
                    value={_.capitalize(status)}
                    data={statusData}
                    placeholder="Choose Status"
                    onChange={handleOnChageSelect}
                    style={{
                      height: "35px",
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} lg={4} className="mt-4">
                  <label className="itemText lable mt-2 C-dark form-label">
                    Privacy Level*
                  </label>
                  <DropDown
                    noShadow
                    name="is_public"
                    value={_.capitalize(is_public)}
                    data={privacyLevelData}
                    placeholder="Choose Privacy Level"
                    onChange={handleOnChageSelect}
                    style={{
                      height: "35px",
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-lg-3 mt-sm-3 mx-4">
                <Col xs={12} sm={12} lg={12}>
                  <label class="itemText lable mt-2 C-dark form-label">
                    Description*
                  </label>
                  <Form.Control
                    rows={6}
                    as="textarea"
                    name="description"
                    value={description}
                    onChange={handleChange}
                    maxLength={500}
                    style={{ fontSize: "13px" }}
                    placeholder="Enter Description"
                    className="form-controls"
                  />
                </Col>
              </Row>
              {savedData && savedData.length > 0 && (
                <Row className="mx-4 mt-3">
                  <Col>
                    <div className="m-0 rounded mt-2 Shadow">
                      <Table responsive className="mb-0 border-bottom-none">
                        <thead>
                          <tr style={{ borderBottom: "3px solid #cdcdcd" }}>
                            <th></th>
                            <th className="col-xs-1 col-sm-1 col-lg-1 C-primary">
                              Id
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Fields Name
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Fields Type
                            </th>
                            <th className="col-xs-3 col-sm-3 col-lg-4 C-primary">
                              Status
                            </th>
                            <th className="col-xs-2 col-sm-2 col-lg-3 C-primary">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <FormFieldListItem
                          savedData={savedData}
                          handleDragEndDataForSavedData={
                            handleDragEndDataForSavedData
                          }
                          handleChildFormModal={handleChildFormModal}
                          handleFormFieldUpdate={handleFormFieldUpdate}
                          handleFormFieldDelete={handleFormFieldDelete}
                        />
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mx-4">
                <div className="mt-5">
                  <Pressable
                    classes="w-25"
                    title="Form Fields +"
                    onPress={handleFormModal}
                    style={{ minWidth: "80px", maxWidth: "150px" }}
                  />
                </div>
              </Row>
              <Row className=" mt-3 mx-4">
                <div className="d-flex justify-content-end">
                  <div className="px-3">
                    <Pressable
                      title="Cancel"
                      onPress={() => navigate("/forms")}
                      classes="bg-light text-black w-25"
                      style={{ minWidth: "100px", maxWidth: "170px" }}
                    />
                  </div>
                  <div>
                    <Pressable
                      title="Save"
                      classes="w-25"
                      loading={isSubmitLoading}
                      disabled={isDisabled}
                      onPress={handleSubmitForm}
                      style={{ minWidth: "100px", maxWidth: "170px" }}
                    />
                  </div>
                </div>
              </Row>
            </Form.Group>
            <div>
              <Row>
                {formModal && (
                  <AddEditModal
                    name="Element"
                    show={formModal}
                    onSubmit={handleFormField}
                    type={isEdit ? "Edit" : "Add"}
                    isDisableSave={isDisabledFormField}
                    closeModal={() => setState({ ...state, formModal: false })}
                  >
                    <AddFormField
                      state={state}
                      setState={setState}
                      handleChange={handleChange}
                      handleOnSubmitOptions={handleOnSubmitOptions}
                      handleOnPressCancel={handleOnPressCancel}
                      handleDragEndData={handleDragEndData}
                    />
                  </AddEditModal>
                )}
              </Row>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
export default AddForm;
