import React, { useState } from "react";
import { Pressable, TextInput } from "@/components/common";
import { Form } from "react-bootstrap";
import _ from "lodash";
import "../index.css";
import FormListItemData from "./FormListItemData";

const FormsInputItemList = ({
  data,
  state,
  setState,
  formValues,
  handleEmail,
  handleSubmit,
  handleChange,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [multipleChoiceGrid, setMultipleChoiceGrid] = useState(true);
  const token = JSON.parse(localStorage.getItem("token"));
  const validateErrors = () => {
    const errors = {};

    const checkRequiredFields = (elements) => {
      elements?.forEach((element) => {
        const findData = formValues?.find(
          (findItem) => +findItem?.form_element_id === +element?.id
        );

        const isArrayFindData = Array.isArray(findData?.value)
          ? !findData?.value?.length
          : !findData?.value;

        if (element.required && isArrayFindData) {
          errors[element.id] = "This field is required.";
        } else if (
          element.required &&
          element.type === "multiple_choice_grid"
        ) {
          const gridOptions = JSON.parse(element.options);
          const rows = gridOptions.rows;
          let allRowsSelected = true;
          rows.forEach((rowItem) => {
            const rowSelectedOptions = findData?.value
              ? findData.value[rowItem]
              : [];
            if (!rowSelectedOptions || rowSelectedOptions.length === 0) {
              allRowsSelected = false;
            }
          });
          if (!allRowsSelected) {
            errors[element.id] =
              "Please select at least one option in each row.";
          }
        }
        const selectedValue = Array.isArray(findData?.value)
          ? findData?.value
          : [findData?.value];

        const changeLowerCaseValue = selectedValue?.map((mapItem) =>
          typeof mapItem === 'string' ? mapItem?.toLowerCase() : mapItem
        );
        const checkParentValueExist = element.elements?.filter((findItem) =>
          changeLowerCaseValue?.includes(findItem.parent_value?.toLowerCase())
        );

        if (
          element.elements &&
          element.elements.length > 0 &&
          checkParentValueExist?.length
        ) {
          checkRequiredFields(checkParentValueExist, formValues);
        }
      });
    };
    if (data && data.length) {
      checkRequiredFields(data);
    }
    return errors;
  };

  const handleValidateSubmit = (e) => {
    const errors = validateErrors();
    if (Object.keys(errors)?.length === 0) {
      handleSubmit(e);
      setMultipleChoiceGrid(false);
    } else {
      setFormErrors(errors);
      const errorId = Object.keys(errors)[0];
      const errorContainer = document.getElementById(`container-${errorId}`);
      if (errorContainer) {
        errorContainer.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="center">
        <Form className="forms-items">
          <div className="inputContainer p-3">
            <Form.Label className="lable mt-1 C-dark text-break">
              {`Email`}
            </Form.Label>
            <TextInput
              placeholder={"Enter your email address"}
              value={state.email}
              name={"email"}
              onChange={(e) => handleEmail(e)}
              lableClass="lable mt-1 c-[black] text-break"
              required={token}
            />
          </div>
          <FormListItemData
            data={data}
            state={state}
            setState={setState}
            handleChange={handleChange}
            formValues={formValues}
            formErrors={formErrors}
            multipleChoiceGrid={multipleChoiceGrid}
            setFormErrors={setFormErrors}
          />
        </Form>
      </div>
      <div className="buttonContainer d-flex justify-content-center">
        <Pressable
          title={"Submit Form"}
          name={"Submit"}
          onPress={handleValidateSubmit}
        />
      </div>
    </div>
  );
};

export default FormsInputItemList;
