import { createSlice } from "@reduxjs/toolkit";
import { getFormList, getFormDetailData, getFormDetails } from "@/services";

const initialState = {
  formList: [],
  getFormDetailsData: {},
  formDetailData: [],
};
export const form = createSlice({
  name: "form",
  initialState,
  reducers: {
    clearFormDetailsReducer: (state, action) => {
      return state = initialState
    },
  },
  extraReducers: {
    [getFormList.fulfilled]: (state, action) => {
      state.formList = action.payload?.data;
    },
    [getFormDetails.fulfilled]: (state, action) => {
      state.getFormDetailsData = action.payload?.data;
    },
    [getFormDetailData.fulfilled]: (state, action) => {
      state.formDetailData = action.payload;
    },
  },
});
export const { clearFormDetailsReducer } = form.actions;
export default form.reducer;
