import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVersionDetails } from "@/services";

const Footer = () => {
  const dispatch = useDispatch();
  const { currentVersion } = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getVersionDetails());
  }, []);
  return (
    <>
      <div
        style={{
          height: "44px",
          position: "fixed",
          bottom: 0,
          color: "#a2a2a2",
          zIndex: 999,
        }}
        className="align-items-center w-100 p-1 Bg-fff F-14 footer body"
      >
        <span className="ms-1">
          School Health © {new Date().getFullYear()} | Version{" "}
            {currentVersion?.name?.padEnd(6, ".0")}
        </span>
        <Link
          to="/pages/privacy-policy"
          className="privacyLink F-14"
          style={{ color: "#a2a2a2",paddingRight:"60px" }}
        >
          Privacy Policy
        </Link>
      </div>
    </>
  );
};
export default Footer;
