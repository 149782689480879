export const statusData = [
  { name: "Active", status: "Active" },
  { name: "Inactive", status: "Inactive" },
];
export const minimumValueData = [
  { name: "0", status: 0 },
  { name: "1", status: 1 },
];
export const maximumValueData = [
  { name: "5", status: 5 },
  { name: "10", status: 10 },
];
export const pdfFormatType = [
  { id: 1, label: "PDF-Landscape" },
  { id: 2, label: "PDF-Portrait" },
];
export const formTypeData = [
  { name: "Input", key: "input" },
  { name: "Select", key: "select" },
  { name: "Radio", key: "radio" },
  { name: "Checkbox", key: "checkbox" },
  { name: "Date", key: "date" },
  { name: "Time", key: "time" },
  { name: "File", key: "file" },
  { name: "Textarea", key: "textarea" },
  { name: "Multiple Choice Grid", key: "multiple_choice_grid" },
  { name: "Linear", key: "linear" },
  { name: "Scale", key: "scale" },
];
export const privacyLevelData = [
  { id: true, name: "Shared" },
  { id: false, name: "Private" },
];
export const DuplicateCurrSelectOptions = [
  {
    id: 1,
    name: "Section",
    value: "section",
  },
  {
    id: 2,
    name: "Unit",
    value: "unit",
  },
  {
    id: 3,
    name: "Lesson",
    value: "lesson",
  },
  {
    id: 4,
    name: "Component",
    value: "component",
  },
  {
    id: 5,
    name: "Paragraph",
    value: "paragraph",
  },
];