import React from "react";
import PublicRoute from "@/routes/PublicRoute";
import { isUserAuthenticated } from "@/helpers";
import ProtectedRoute from "@/routes/ProtectedRoute";
import Topbar from "@/components/Layout/Topbar/Topbar";
import FormsInput from "./widgets/formsInput";

const Forms = ({ toggleSideNav }) => {
  return (
    <>
      {isUserAuthenticated() ? (
        <>
          <Topbar toggleSideNav={toggleSideNav} />
          <ProtectedRoute>
            <FormsInput />
          </ProtectedRoute>
        </>
      ) : (
        <PublicRoute>
          <FormsInput />
        </PublicRoute>
      )}
    </>
  );
};

export default Forms;
