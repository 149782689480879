import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isUserAuthenticated, pusher } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./theme/Colors.css";
import { getMessaging, getToken } from "firebase/messaging";
import * as config from "./config";
import {
  getLicensePermissions,
  getUserProfile,
  updateDeviceToken,
} from "./services";
import { updateNotificationData } from "./reducers/dashboard";
import { firebaseApp } from "./index";
import "./App.css";
import "./index.css";
import "./common.css";

import "@pages/auth/index.css";
import { router as ShRouter } from "./routes/routes";

const App = (props) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.auth.user?.id);
  useEffect(() => {
    const channel = pusher.subscribe("notification");
    channel.bind("new", function (data) {
      if (+data?.notification?.user_id === currentUserId) {
        dispatch(updateNotificationData(data));
      }
    });

    return () => channel.unbind();
  }, []);
  useEffect(() => {
    if (isUserAuthenticated()) {
      // Req user for notification permission
      requestPermission();

      dispatch(getUserProfile());
      dispatch(getLicensePermissions());
    }
  }, [isUserAuthenticated()]);


  useEffect(() => {
    setTimeout(() => {
      const color = window.location.href?.includes("curriculum") ? '#1471b0' : '#c60970'
      document.documentElement.style.setProperty("--primary-color", color);
      document.documentElement.style.setProperty("--bg-primary-color", color);
    }, 100);
  }, [window.location.href]);
  async function requestPermission() {
    if ("serviceWorker" in navigator) {
      const messaging = getMessaging(firebaseApp);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const token = await getToken(messaging, {
            vapidKey: config.VAPID_KEY,
          });
          let data = {
            type: "web",
            token: token,
            status: "active",
          };
          dispatch(updateDeviceToken(data));
        } catch (e) { }
      }
    }
  }

  return (
    <React.Fragment>
      <RouterProvider router={ShRouter} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"colored"}
      />
    </React.Fragment>
  );
};

export default App;
