import axios from 'axios';
import { apiRequest, apiRequestBodyParams, apiRequestParams } from './equipment.services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as config from '../config';

export const getCurriculumList = apiRequest('get', 'curricula', 'GET_CURRICULUM_LIST');

export const getVersions = apiRequest('get', 'versions', 'GET_VERSIONS_LIST');

export const getSections = apiRequest('get', 'sections', 'GET_SECTIONS_LIST');

export const getParagraph = apiRequestParams('get', 'components', 'GET_PARAGRAPH');

export const saveCurriculumData = apiRequest('post', 'curriculum/upsert-activity', 'SAVE_CURRICULUM')

export const getCurriculumData = apiRequestParams('get', 'user-activity', 'GET_CURRICULUM');

export const deleteCurriculumActivity = apiRequestParams('delete', 'delete-activity', 'DELETE_ACTIVITY');

export const createSection = apiRequest('post', 'sections/create', 'CREATE_SECTION');

export const createUnit = apiRequest('post', 'units/create', 'CREATE_UNIT');

export const createLesson = apiRequest('post', 'lessons/create', 'CREATE_LESSON');

export const createComponent = apiRequestBodyParams('post', 'components/create', 'CREATE_COMPONENT');

export const createParagraph = apiRequestBodyParams('post', 'paragraphs/create', 'CREATE_PARAGRAPH');

export const getAllParagraphs = apiRequest('get', 'paragraphs', 'GET_PARAGRAPHS');

export const editSection = apiRequestParams('patch', 'sections/update', 'EDIT_SECTION');

export const editUnit = apiRequestParams('patch', 'units/update', 'EDIT_UNIT');

export const editLesson = apiRequestParams('patch', 'lessons/update', 'EDIT_LESSONS');

export const editComponent = apiRequestParams('patch', 'components/update', 'EDIT_COMPONENT');

export const editParagraph = apiRequestParams('patch', 'paragraphs/update', 'EDIT_PARAGRAPH');

export const duplicateCurriculum = apiRequest('post', 'curricula/duplicate', 'DUPLICATE_CURRICULA');

export const deleteCurriculum = apiRequestParams("delete", 'curricula/delete', 'DELETE_CURRICULA');

export const editCurriculum = apiRequestParams('patch', 'curricula/update', 'EDIT_CURRICULA');

export const exportCurriculumPdf = createAsyncThunk(
  "EXPORT_CURRICULUM_PDF",
  async (data) => {
    const { id, payload } = data;
    try {
      const URL = `${config.SOCIAL_URL}curriculum/export/${id}`;
      const response = await axios.get(URL, {
        ...payload,
        responseType: "arraybuffer",
      });
      return response;
    } catch (err) {
      return err.response;
    }
  }
);
