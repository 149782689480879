import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Banner from "../pages/auth/Banner";
import Logo from "../pages/auth/Logo";

import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/auth/index.css";
import { setUserToken } from "@/helpers";

const SocialCallback = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  function removeQueryString(url) {
    const urlObj = new URL(url);

    urlObj.search = "";
    return urlObj.toString();
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    const expiresAt = params.get("expiresAt");
    const getMessage = params.get("message");
    const user = JSON.parse(params.get("user"));
    const redirectURL = params.get("redirect_uri");
    if (accessToken && user && user.id && redirectURL) {
      setUserToken({ accessToken, expiresAt, user });
      navigate(`${redirectURL}`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else if (accessToken && user && user.id) {
      setUserToken({ accessToken, expiresAt, user });
      window.location.reload();
      navigate("/dashboard");
    } else {
      toast.error(getMessage || "Invalid Social Login attempt!");
      navigate("/");
    }
  });

  return (
    <div className="App">
      <Row className="p-0 m-0">
        <Banner />
        <Col
          md={5}
          xs={12}
          sm={12}
          className="d-flex flex-column align-items-center p-0 m-0 Bg-fff"
          style={{ zIndex: "1" }}
        >
          <Logo />
          <div className="forgotPasswordForm pb-4">
            <p className="welcome center">Unexpected</p>
            <p className="signIn center">Error</p>

            <div className="d-flex flex-column">
              <p className="resetText C-818188 ">Go to Home Page</p>
              <Link to="/" className="form">
                <input
                  type="button"
                  value="Home"
                  className="bold C-fff Bg-primary"
                />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SocialCallback;
