import React from 'react';
import { Col, Row } from "react-bootstrap";
import { Pressable, Container } from './common';
import { MdOutlineFilterList } from 'react-icons/md';

const FilterForm = (props) => {
  let { name, onFilterClick, onAddClick, onClearFilter, isClearable, isDisableAdd, dropdownButton, label } = props;
  return (
    <Container classes='align-items-between justify-content-between'>
      {onAddClick &&
        <Col md={{order:0, span: 'auto'}}>
          <Pressable
            title={`Add ${name} +`}
            classes='add-btn mt-2 text-nowrap'
            onPress={onAddClick}
            disabled={isDisableAdd}
          />
        </Col>
      }
      {dropdownButton &&
        <Col md={{ span: 'auto', order: 1 }} className='mt-2'>
          {dropdownButton}
        </Col>
      }
      <Col as={Row} md={{ order: 2, span: 'auto'}} className='m-0 p-0 filter-container'>
        {props.children}
      </Col>

      <Col md={{ order: 1, span: 'auto' }} className='d-flex flex-row justify-content-end mt-2'>
        <div className='d-flex flex-column align-items-end'>
          <Pressable
            classes={`searchBtn d-flex ${label === 'curriculum' ? 'curr-btn d-flex justify-content-center align-items-center' : ''}`}
            disabled={!onFilterClick}
            style={{ height: '30px', minWidth: '85px', fontSize: '14px' }}
            onPress={onFilterClick}
            name={label}
          ><p className='C-fff bold'>Filter &nbsp; </p> <MdOutlineFilterList size={18}/>
          </Pressable>
          {isClearable && <button
            disabled={!onClearFilter}
            className={`edit-btn ${label === 'curriculum' ? 'C-Primary' : 'C-curriculum'}`}
            style={{ height: '15px', fontSize: '11px' }}
            onClick={onClearFilter}
          ><u>Clear Filter</u>
          </button>}
        </div>
      </Col>
    </Container>
  )
}
export default FilterForm;