import { combineReducers } from "redux";
import auth from "./auth";
import location from './location';
import equipment from './equipment';
import people from './people';
import dashboard from './dashboard'
import report from "./report";
import credential from './credentials'
import course from "./course";
import curriculum from "./curriculum";
import form from './form'
import { rtkQuery } from "@/api/curriculum/rtk-setup";

export default combineReducers({
  auth,
  location,
  equipment,
  people,
  dashboard,
  report,
  credential,
  course,
  curriculum,
  form,
  [rtkQuery.reducerPath]: rtkQuery.reducer
});
