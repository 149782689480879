import React, { useRef, useState } from "react";
import { TextInput } from "@/components/common";
import { Form } from "react-bootstrap";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import "../index.css";
import { uploadFile } from "@/services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdInfo, MdOutlineFileUpload } from "react-icons/md";

const ErrorMessage = ({ message }) => {
    return (
        <div className="d-flex mt-2">
            <MdInfo color="#dc3545" />
            <p className="ms-1 F-12 fw-normal" style={{ lineHeight: "1.5", verticalAlign: "middle" }}>{message}</p>
        </div>
    );
};

const FormListItemData = ({ data,
    state,
    setState,
    formValues,
    formErrors,
    handleChange,
    setFormErrors,
    multipleChoiceGrid,
}) => {

    const [selectedOptions, setSelectedOptions] = useState({});
    const [fileName, setFileName] = useState('');
    const dispatch = useDispatch();

    const errorRef = useRef(null);
    const tableRef = useRef(null);

    const handleScroll = (event, id) => {
        const scrollLeft = event.target.scrollLeft;
        const cellsWithMyClass = document.querySelectorAll(`.tableShadowClass${id}`);
        const boxShadowStyle = scrollLeft === 0 ? 'none' : 'inset -10px 0 10px -10px #E0E0E0';
        cellsWithMyClass.forEach(cell => {
            cell.style.boxShadow = boxShadowStyle;
        });
    };

    const handleFileChange = async (event, id) => {
        const { target } = event
        const { files } = target
        if (files[0]?.size > 209715200) {
            toast.error("File size should be less than 200MB");
            return;
        }
        if (files && files[0]) {
            if (files[0]) {
                const file = new FormData()
                file?.append('file', files[0], files[0].name)
                setFileName((prev) => ({ ...prev, [id]: files?.[0]?.name }))
                const res = await dispatch(uploadFile(file)).unwrap();
                if (res?.url) {
                    handleChange(id, res?.url)
                } else {
                    toast.error("File content too large.");
                }
            }
        }
    };

    const handleValidMultiChoiceGrid = (newOptions, element) => {
        const errors = { ...formErrors };
        const gridOptions = JSON.parse(element.options);
        const rows = gridOptions.rows;
        let allRowsSelected = true;
        rows.forEach((rowItem) => {
            const rowSelectedOptions = newOptions?.[element?.id]
                ? newOptions[element?.id][rowItem]
                : null;
            if (!rowSelectedOptions) {
                allRowsSelected = false;
            }
        });
        if (element.required === 1 && !allRowsSelected) {
            errors[element.id] = "Please select at least one option in each row.";
        } else {
            delete errors[element.id];
        }
        setFormErrors(errors);
    };

    const handleOptionSelect = (rowValue, columValue, element) => {
        setSelectedOptions(prevState => {
            const newOptions = {
                ...prevState,
                [element.id]: {
                    ...prevState[element.id],
                    [rowValue]: columValue
                }
            };
            handleValidMultiChoiceGrid(newOptions, element);
            return newOptions;
        });

        const saveData = {
            form_element_id: `${element?.id}`,
            value: { [rowValue]: columValue },
        };

        const existingIndex = state?.formData?.findIndex(
            (data) => data?.form_element_id === saveData?.form_element_id
        );

        if (existingIndex !== -1) {
            const existingData = state?.formData?.[existingIndex];
            const newValue = { ...existingData?.value, ...saveData?.value };

            setState((prevState) => ({
                ...prevState,
                formData: prevState.formData.map((data, index) => {
                    if (index === existingIndex) {
                        return {
                            ...data,
                            value: newValue,
                        };
                    }
                    return data;
                }),
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                formData: [...prevState.formData, saveData],
            }));
        }
    };
    const onKeyDown = (e) => {
        e.preventDefault()
    }

    function getRangeWithStep(min, max) {
        let range = [];
        for (let i = min; i <= max; i++) {
            range.push(i);
        }
        return range;
    }

    return (
        data?.map((element) => {
            const elementValue = _.find(formValues, { form_element_id: `${element?.id}` })?.value;
            const isArrayFindData = Array.isArray(elementValue) ? !elementValue?.length : !elementValue
            const isRequiredAndEmpty = element?.required && isArrayFindData;
            const getSelectedValue = formValues?.find((findItem) => findItem?.form_element_id == element?.id)
            switch (element?.type) {
                case "input":
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <TextInput
                                placeholder={element?.place_holder}
                                value={
                                    _.find(formValues, { form_element_id: `${element?.id}` })
                                        ?.value || ""
                                }
                                maxlength={150}
                                name={element?.key}
                                onChange={(e) => handleChange(element?.id, e.target.value)}
                                lableClass="lableInput mt-1 c-[black]  text-break"
                            />
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "textarea":
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <TextInput
                                lable={element?.label + (element?.required ? " *" : "")}
                                placeholder={element?.place_holder}
                                isTextArea="textarea"
                                name={element?.key}
                                value={
                                    _.find(formValues, { form_element_id: `${element?.id}` })
                                        ?.value || ""
                                }
                                onChange={(e) => handleChange(element?.id, e.target.value)}
                                style={{ height: "100px", resize: "none" }}
                                maxlength={300}
                                lableClass="lableInput mt-1 C-dark text-break"
                            />
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "date":
                    const dateStr = _.find(formValues, {
                        form_element_id: `${element?.id}`,
                    })?.value;
                    const selectedDate = dateStr ? new Date(dateStr) : null;
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label
                                type="select"
                                className="lableInput mt-1 C-dark text-break"
                            >
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label><br />
                            <input
                                type="date"
                                value={selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""}
                                onChange={(e) => handleChange(element?.id, e.target.value, "date")}
                                placeholder={`Choose Date`}
                                id="formInputDate"
                                onKeyDown={onKeyDown}
                                style={{ padding: "0.7% 2% 0.7% 1%" }}
                                className=" border w-25 Shadow border rounded dropDown"
                                // min={new Date().toISOString().substring(0, 10)}
                                max="2200-01-01"
                            />
                            {/* <ReactDatePicker
                      selected={selectedDate}
                      onChange={(date) => handleChange(element?.id, date, "date")}
                      placeholderText={`Choose Date`}
                      dateFormat="MM/d/yyyy"
                      className="p-2 border rounded dropDown "
                      // minDate={new Date()}
                      maxDate={new Date("2200 01 01")}
                    /> */}
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "radio":
                    const getChildValueRadio = element?.elements?.filter((filterItem) => filterItem?.parent_value?.toLowerCase() === getSelectedValue?.value?.toLowerCase())
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <br />
                            <div>
                                {_.isArray(JSON.parse(element.options))
                                    ? JSON.parse(element.options).map((item) => {
                                        return (
                                            <div className="radioInputContainer d-flex">
                                                <Form.Check
                                                    inline
                                                    style={{ fontSize: "12px" }}
                                                    lableClass="radioLableClass"
                                                    name={element?.key}
                                                    type="radio"
                                                    className="align-self-center pb-2"
                                                    checked={
                                                        _.find(formValues, {
                                                            form_element_id: `${element?.id}`,
                                                        })?.value === item
                                                    }
                                                    id={`${element.key}-${item}`}
                                                    onClick={() =>
                                                        handleChange(element?.id, item, element)
                                                    }
                                                />
                                                <Form.Label
                                                    className="text-break align-self-center"
                                                    style={{ fontSize: "15px" }}
                                                    htmlFor={`${element.key}-${item}`}
                                                >
                                                    {item}
                                                </Form.Label>
                                            </div>
                                        );
                                    })
                                    : null}
                            </div>
                            {!_.isEmpty(element.elements) && getChildValueRadio?.length > 0 &&
                                <FormListItemData
                                    data={getChildValueRadio}
                                    state={state}
                                    setState={setState}
                                    handleChange={handleChange}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    multipleChoiceGrid={multipleChoiceGrid}
                                />}
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "checkbox":
                    const convertIntoLowerCase = getSelectedValue?.value?.map(mapItem => mapItem?.toLowerCase());
                    const getChildValueCheckbox = element?.elements?.filter((filterItem) => convertIntoLowerCase?.includes(filterItem?.parent_value?.toLowerCase()))
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <div className="">
                                {_.isArray(JSON.parse(element.options)) &&
                                    JSON.parse(element.options).map((item) => {
                                        return (
                                            <div className="checkBoxDivContainer d-flex">
                                                <Form.Check
                                                    inline
                                                    style={{ fontSize: "13px" }}
                                                    // label={item}
                                                    name={element?.key}
                                                    type="checkbox"
                                                    defaultChecked={_.find(formValues, {
                                                        form_element_id: `${element?.id}`,
                                                    })?.value.includes(item?.id)}
                                                    id={`chackbox-${item}`}
                                                    onChange={(e) => handleChange(element?.id, item, element, e)}
                                                    className="checkboxContainer mb-2 text-break align-self-center"
                                                />
                                                <Form.Label
                                                    className="text-break"
                                                    style={{ fontSize: "15px" }}
                                                    htmlFor={`chackbox-${item}`}
                                                >
                                                    {item}
                                                </Form.Label>
                                            </div>
                                        );
                                    })}
                            </div>
                            {!_.isEmpty(element.elements) && getChildValueCheckbox?.length > 0 &&
                                <FormListItemData
                                    data={getChildValueCheckbox}
                                    state={state}
                                    setState={setState}
                                    handleChange={handleChange}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    multipleChoiceGrid={multipleChoiceGrid}
                                />}
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "select":
                    const getChildValueSelect = element?.elements?.filter((filterItem) => filterItem?.parent_value?.toLowerCase() === getSelectedValue?.value?.toLowerCase())
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <Select
                                styles={{
                                    control: (base, state) => ({ ...base, fontSize: "12px", border: "0.035rem solid #cdcdcd", minHeight: "30px", borderRadius: "5px", }),
                                    dropdownIndicator: (prev) => ({ ...prev, padding: "1px" }),
                                }}
                                value={
                                    _.find(formValues, { form_element_id: `${element}` })?.value
                                }
                                placeholder={element?.place_holder}
                                className="border-0 rounded text-break Shadow"
                                onChange={(e) => handleChange(element?.id, e.value, element)}
                                options={JSON.parse(element?.options)}
                            />
                            {!_.isEmpty(element.elements) && getChildValueSelect?.length > 0 &&
                                <FormListItemData
                                    data={getChildValueSelect}
                                    state={state}
                                    setState={setState}
                                    handleChange={handleChange}
                                    formValues={formValues}
                                    formErrors={formErrors}
                                    multipleChoiceGrid={multipleChoiceGrid}
                                />}
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "file":
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            {/* <input
                      type="file"
                      fileName={element?.key}
                      size="md"
                      style={{ fontSize:'12px',padding:"0.26% 1%",height:'30px'}}
                      className="border Shadow cursor-pointer"
                      onChange={(e) =>
                        handleFileChange(e, element?.id)
                      }
                    /> */}
                            <div>
                                <input
                                    type={`file`}
                                    className="d-none"
                                    id={`file-${element.id}`}
                                    onChange={(e) => handleFileChange(e, element?.id)}
                                />
                                <label htmlFor={`file-${element?.id}`}>
                                    <p className="fw-normal text-break" style={{ fontSize: "12px", color: `${fileName ? 'black' : '#6c757d'}` }}>
                                        {fileName?.[element?.id] || element?.place_holder}
                                    </p>
                                    <p className="cursor-pointer border mt-2 rounded px-2 py-1 d-flex justify-content-center align-items-center C-primary" style={{ fontSize: "13px", width: "100px" }}>
                                        <MdOutlineFileUpload className="me-1" /> Add File
                                    </p>
                                </label>
                            </div>
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "time":
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label><br />
                            <input
                                type="time"
                                style={{ padding: "0.7% 2% 0.7% 1%", fontSize: "12px" }}
                                className="w-25  Shadow border"
                                id="formInputTime"
                                onKeyDown={onKeyDown}
                                onChange={(e) => {
                                    handleChange(element?.id, e.target.value);
                                }}
                                value={
                                    _.find(formValues, { form_element_id: `${element?.id}` })
                                        ?.value
                                }
                            />
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                case "multiple_choice_grid": {
                    const { rows, columns } = JSON.parse(element?.options);
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 multiple-choice-grid ${multipleChoiceGrid && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <div style={{ overflowX: 'auto' }} onScroll={(e) => handleScroll(e, element?.id)}>
                                <table ref={tableRef} className="table border-bottom">
                                    <thead>
                                        <tr>
                                            <td className={`tableShadowClass${element?.id} headingWidth`} style={{ maxWidth: "350px", position: "sticky", left: -1 }}
                                            ></td>
                                            {columns?.map((col, colIndex) => (
                                                <th className="lableInput text-break C-dark manageWidths" scope="col" key={colIndex}>
                                                    {col}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td style={{ maxWidth: "350px", position: "sticky", left: -1 }} className={`tableShadowClass${element?.id} headingWidth lable text-wrap C-dark text-break`}>{row}</td>
                                                {columns?.map((col, colIndex) => (
                                                    <td className="border align-middle justify-self-center manageWidths" key={colIndex}>
                                                        <div className=" d-flex justify-content-center m-auto" style={{ width: '16px' }}>
                                                            <input
                                                                type="radio"
                                                                name={`option-${element.id}-${rowIndex}`}
                                                                checked={selectedOptions?.[element.id]?.[row] === col}
                                                                onChange={() =>
                                                                    handleOptionSelect(row, col, element)
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {multipleChoiceGrid && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                }
                case "linear": {
                    const { min, max, step, prefix, suffix } = JSON.parse(element?.options);
                    const range = getRangeWithStep(parseInt(min), parseInt(max));
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 multiple-choice-grid ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            <div className="d-flex align-items-center justify-content-between mt-3">
                                {prefix && <span>{prefix}</span>}
                                {range.map((num) => (
                                    <label key={num}>
                                        <input
                                            type="radio"
                                            name={element?.key}
                                            value={num}
                                            className="d-flex"
                                            onChange={(e) => {
                                                handleChange(element?.id, e.target.value);
                                            }}
                                            checked={
                                                parseInt(_.find(formValues, { form_element_id: `${element?.id}` })?.value) === num
                                            }

                                        />
                                        {num}
                                    </label>
                                ))}
                                {suffix && <span>{suffix}</span>}
                            </div>
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                }
                case "scale":
                    const options = JSON.parse(element.options);
                    return (
                        <div id={`container-${element.id}`} ref={errorRef} className={`inputContainer p-3 ${isRequiredAndEmpty && formErrors[element.id] ? 'border border-danger' : ''}`}>
                            <Form.Label className="lableInput mt-1 C-dark text-break">
                                {element?.label + (element?.required ? " *" : "")}
                            </Form.Label>
                            {options.display_value.map((item, index) => (
                                <div key={index} className="radioInputContainer d-flex align-items-center">
                                    <Form.Check
                                        inline
                                        style={{ fontSize: "12px" }}
                                        lableClass="radioLableClass"
                                        name={element?.key}
                                        type="radio"
                                        id={`${element.key}-${item}`}
                                        className="align-self-center pb-2"
                                        checked={_.find(formValues, { form_element_id: `${element?.id}` })?.value.includes(options.data_value[index])}
                                        onChange={() => handleChange(element?.id, options.data_value[index], element)}
                                    />
                                    <Form.Label
                                        className="text-break align-self-center"
                                        style={{ fontSize: "15px" }}
                                        htmlFor={`${element.key}-${item}`}
                                    >
                                        {item}
                                    </Form.Label>
                                </div>
                            ))}
                            {isRequiredAndEmpty && formErrors[element.id] ? <ErrorMessage message={formErrors[element.id]} /> : ''}
                        </div>
                    );
                default:
                    return null;
            }
        })
    )
}

export default FormListItemData;