import { DropDown } from '@/components/common'
import React from 'react'
import { Col, Modal } from 'react-bootstrap'

const TimezoneModal = (props) => {
    const { showTimeZoneModal, onHide, handleChange, moduleName, data, value, handleSubmit, handleLogout } = props;
    return (
        <>
            <Modal
                show={showTimeZoneModal}
                // onHide={() => onHide()}
                backdrop={'static'}
                centered
            >
                <Modal.Header style={{ height: '50px' }}>
                    <Modal.Title className='C-primary modalHeader text-truncate bold'>Select {moduleName}</Modal.Title>
                    <button type='submit'  onClick={(e) => handleLogout(e)} className='pressable searchBtn d-flex btn btn-primary' style={{height: '30px', minWidth: '85px', fontSize: '14px'}}><p className='C-fff bold'> Sign out</p> </button>
                    {/* <CloseButton onClose={() => onHide()} /> */}
                </Modal.Header>
                <Modal.Body style={{ minHeight: window.innerHeight / 4.8 }}>
                    <div className="d-flex  align-items-center">
                        <Col xs={4} className='ps-lg-0 my-3'>
                            Select Time Zone
                        </Col>

                        <Col xs={8} className='ps-lg-0 my-2'>
                            <DropDown
                                noShadow
                                searchable
                                name='timeZone'
                                // lable='Time Zone'
                                placeholder='Select Time zone:'
                                value={value?.name || value?.text}
                                data={data}
                                onChange={(e) => handleChange(JSON.parse(e.target.id))}
                            />
                        </Col>
                    </div>
                    <button type='submit' onClick={handleSubmit} className='submit center Bg-primary C-fff bold mt-4' >Submit </button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TimezoneModal
