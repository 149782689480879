// import AccessoriesForm from "./AccessoriesForm";
// import AccessoriesItem from "./AccessoriesItem";
import ActivityItem from "./ActivityItem";
// import ActivityList from "./ActivityList";
// import AddEditLocation from "./AddEditLocation";
// import AddEditModal from "./AddEditModal";
// import AedInspection from "./aedInspection";
// import DeleteModal from "./DeleteModal";
import EquipmentItem from "./EquipmentItem";
// import FilterForm from "./FilterForm";
// import InspectionInfo from "./InspectionInfo";
// import IncidentInfo from './IncidentInfo'
// import Layout from "./Layout";
// import LocationForm from "./LocationForm";
// import LocationItem from "./LocationItem";
// import NotFound from "./NotFound";
// import Notification from "./Notification";
// import Pages from "./Pages";
// import PaginationComponent from "./PaginationComponent";
// import ShowDescription from "./ShowDescription";
import SocialCallback from "./SocialCallback";
import { lazy } from "react";
import FilterForm from "./FilterForm";
const AccessoriesForm = lazy(() => import('./AccessoriesForm'))
const AccessoriesItem = lazy(() => import('./AccessoriesItem'))
// const ActivityItem = lazy(() => import('./ActivityItem'))
const AddEditLocation = lazy(() => import('./AddEditLocation'))
const AddEditModal = lazy(() => import('./AddEditModal'))
const AedInspection = lazy(() => import('./aedInspection'))
const DeleteModal = lazy(() => import('./DeleteModal'))
// const EquipmentItem = lazy(() => import('./EquipmentItem'))
// const FilterForm = lazy(() => import('./FilterForm'))
const InspectionInfo = lazy(() => import('./InspectionInfo'))
const IncidentInfo = lazy(() => import('./IncidentInfo'))
// const Layout = lazy(() => import('./Layout'))
const LocationForm = lazy(() => import('./LocationForm'))
const LocationItem = lazy(() => import('./LocationItem'))
const NotFound = lazy(() => import('./NotFound'))
const Notification = lazy(() => import('./Notification'))
const Pages = lazy(() => import('./Pages'))
const PaginationComponent = lazy(() => import('./PaginationComponent'))
const ShowDescription = lazy(() => import('./ShowDescription'));
const ActivityList = lazy(() => import('./ActivityList'))
const DroppableContext = lazy(() => import('./DroppableContext'));
const ExportPdfModal = lazy(() => import("./Layout/Curriculum/ExportPdfModal"));
const CheckConfirmationModal = lazy(() => import("./CheckConfirmationModal"));
const AddIntroductionModal = lazy(() =>
  import("./Layout/Curriculum/AddIntroductionModal")
);

export {
    AccessoriesForm,
    AccessoriesItem,
    ActivityItem,
    ActivityList,
    DroppableContext,
    AddEditLocation,
    AddEditModal,
    AedInspection,
    DeleteModal,
    EquipmentItem,
    FilterForm,
    InspectionInfo,
    IncidentInfo,
    // Layout,
    LocationForm,
    LocationItem,
    NotFound,
    Notification,
    Pages,
    PaginationComponent,
    ShowDescription,
    SocialCallback,
    ExportPdfModal,
    CheckConfirmationModal,
    AddIntroductionModal
}