import DefaultLayout from '@/components/Layout/DefaultLayout'
import { isUserAuthenticated } from '@/helpers'
import React, { Suspense } from 'react'
import { Spinner } from 'react-bootstrap'
import { IoReloadOutline } from 'react-icons/io5'
import { Navigate } from 'react-router-dom'

class ProtectedRoute extends React.Component {
  constructor(props) {

    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  handleReload() {
    window.location.reload()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isUserAuthenticated() ) {
      setTimeout(()=>{
        window.location.reload();
      },0)
    }
  }

  render() {
    if (!isUserAuthenticated()) {
      return <Navigate to={'/'} />;
    }
    if (this?.state?.hasError) {     
      return (
        <Suspense>

          <div className='flex-grow-1 center'>
            <div className='text-center errorPage'>
              <h1>Something went wrong 😢</h1>
              <div className='F-14' role='button' onClick={this.handleReload}>Please reload the page <IoReloadOutline /></div>
            </div>
          </div>
        </Suspense>
      )
    }
    if (this?.props?.hideNav) {
      return (
        <Suspense>{this?.props?.children}</Suspense>
      )
    }
    return (
      <DefaultLayout isSearch={this?.props?.isSearch}>
        <Suspense
          fallback={
            <div className='d-flex center flex-grow-1 vh-100'>
              <Spinner animation='border' className='d-flex align-self-center' />
            </div>
          }>
          {this?.props?.children}
        </Suspense>
      </DefaultLayout>
    )
  }
}

export default ProtectedRoute