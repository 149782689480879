import { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Spinner } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import logo from "@images/logo.png";
import { addFormsData, getFormContent } from "@/services";
import { isUserAuthenticated, usePageTitle } from "@/helpers";
import AddressBar from "@/components/Layout/AddressBar";
import FormsInputItemList from "./FormsInputItemList";
import { toast } from "react-toastify";
import moment from "moment";

const isValidEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const FormsInput = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [state, setState] = useState({
    formValues: [],
    formData: [],
    loader: true,
    email: "",
    validation: "",
    isCheck: false,
  });
  const params = useParams();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const slug = params.slug;

  usePageTitle("School Health Form");

  useEffect(() => {
    if (slug) {
      dispatch(getFormContent(slug))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState((prev) => ({
              ...prev,
              loader: false,
              formValues: res?.data?.form_elements,
            }));
            setData(res?.data);
          } else {
            setState((prev) => ({ ...prev, loader: false }));
            navigate("/", { replace: true });
          }
        })
        .catch((e) => {
          setState((prev) => ({ ...prev, loader: false }));
          navigate("*", { replace: true });
        });
    }
  }, [slug]);

  const handleFormChange = (elementId, value, parent) => {
    if (elementId) {
      setState((prev) => {
        const updatedFormValues = [...prev.formData];
        const elementIndex = updatedFormValues?.findIndex(
          (element) => +element.form_element_id === +elementId
        );
        if (elementIndex !== -1) {
          if (Array.isArray(updatedFormValues[elementIndex].value)) {
            const checkboxValues = updatedFormValues[elementIndex].value;
            const updatedCheckboxValues = checkboxValues.includes(value)
              ? checkboxValues.filter((v) => v !== value)
              : [...checkboxValues, value];
            updatedFormValues[elementIndex].value = updatedCheckboxValues;
          } else {
            updatedFormValues[elementIndex].value = value;
          }
        } else if (parent === "date") {
          const dateFormat = moment(value).format("YYYY/MM/DD");
          updatedFormValues.push({
            form_element_id: `${elementId}`,
            value: dateFormat,
          });
        } else if (parent?.type === "checkbox") {
          updatedFormValues.push({
            form_element_id: `${elementId}`,
            value: [value],
          });
        } else {
          updatedFormValues.push({
            form_element_id: `${elementId}`,
            value: value,
          });
        }

        const removeChildElements = (elements, formValues) => {
          elements.forEach((element) => {
            const childIndex = formValues.findIndex(
              (indexItem) => +indexItem.form_element_id === +element.id
            );
            if (childIndex !== -1) {
              formValues.splice(childIndex, 1);
            }
            if (element.elements && element.elements.length > 0) {
              removeChildElements(element.elements, formValues);
            }
          });
        };

        if (parent?.elements && parent.elements.length > 0) {
          removeChildElements(parent.elements, updatedFormValues);
        }

        return { ...prev, formData: updatedFormValues };
      });
    } else {
      setState((prev) => ({
        ...prev,
        [value?.target?.name]: value?.target?.value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state?.email && !isValidEmail(state.email.trim())) {
      toast.error("Please enter Valid Email Address");
    } else {
      let payloadData = {
        email: state.email,
        form_values: state.formData,
      };
      setState((prev) => ({ ...prev, loader: true }));
      dispatch(addFormsData({ id: data?.id, data: payloadData }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState((prev) => ({
              ...prev,
              formData: [],
              loader: false,
              isCheck: false,
            }));
            toast.success(res?.message);
          } else {
            setState((prev) => ({ ...prev, loader: false, formData: [] }));
            toast.error(res?.message);
          }
        })
        .catch((error) => {
          setState((prev) => ({
            ...prev,
            formData: [],
            loader: false,
            isCheck: false,
          }));
          toast.error(error?.message);
        });
    }
  };

  const handleEmail = (e) => {
    setState((prev) => ({ ...prev, email: e?.target?.value }));
  };

  return (
    <div className="bg-light">
      {!isUserAuthenticated() ? (
        <div className="d-flex justify-content-start col-12 Bg-fff">
          <div className="p-3">
            <Link to={"/"}>
              <img alt="logo" src={logo} className="w-50" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="w-100">
          <AddressBar
            page={[
              {
                name:
                  data?.name?.length > 65
                    ? data?.name?.slice(0, 65) + "..."
                    : data?.name || "",
              },
            ]}
          />
        </div>
      )}
      {state.loader && (
        <div className="center" style={{ height: "75vh" }}>
          <Spinner
            animation="border"
            size="lg"
            style={{ height: "55px", width: "55px" }}
          />
        </div>
      )}

      <div>
        {!state.loader && (
          <div>
            <div className="d-flex inputContainerHeader p-3">
              <Form.Label
                type="select"
                className="fs-2 fw-bold lable mt-1 C-black text-break"
              >
                {data?.name}
              </Form.Label>
              <Form.Label
                type="select"
                className="fs-6 fw-normal lable mt-1 C-black text-break"
              >
                {data?.description}
              </Form.Label>
            </div>
            <div>
              <FormsInputItemList
                state={state}
                setState={setState}
                handleEmail={handleEmail}
                data={data?.form_elements}
                handleSubmit={handleSubmit}
                formValues={state.formData}
                handleChange={handleFormChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormsInput;
