import { getQueryParams } from "@/helpers"

export function getCurriculumList(builder) {
    return builder.query({
        query: () => `curricula`,
    })
}
export const getSections = (builder) => {
    return builder.query({
        query: (data) => `sections?${getQueryParams(data)}`
    })
}

export const getVersions = (builder) => {
    return builder.query({
        query: ( data ) => `versions?${getQueryParams(data)}`
    })
}

export const getParagraph = (builder) => {
    return builder.query({
      query: (data) => `components/${getQueryParams(data)}`,
    });
}

export const deleteCurriculumActivity = (builder) => {
    return builder.query({
      query: (data) => `delete-activity/${data}`,
    });

}

export const getCurriculumData = (builder) =>{
    return builder.query({
        query: (data) => `user-activity/${data}`,
      });
} 
