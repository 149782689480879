import React, { Suspense } from "react";
import { isUserAuthenticated } from "@/helpers";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const PublicRoute = ({ children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectURL = params.get("redirect_uri");
  const parentCategories = localStorage.getItem("permissions");
  const parseParentCategories = JSON.parse(parentCategories);
  const checkPermission = parseParentCategories?.some((someItem) =>
    redirectURL?.includes(someItem)
  );
  if (redirectURL && isUserAuthenticated() && !checkPermission) {
    toast.error("Access denied!");
    return <Navigate to={"/dashboard"} />;
  } else if (redirectURL && isUserAuthenticated() && checkPermission) {
    <Navigate to={`${redirectURL}`} />;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } else if (isUserAuthenticated()) {
    return <Navigate to={"/dashboard"} />;
  }
  return (
    <div>
      <Suspense>{children}</Suspense>
    </div>
  );
};

export default PublicRoute;
