import PublicRoute from '@/routes/PublicRoute'
import React from 'react'
import Pages from './Pages'
import { isUserAuthenticated } from '@/helpers'
import ProtectedRoute from '@/routes/ProtectedRoute'
import Topbar from './Layout/Topbar/Topbar'

const Page = ({ toggleSideNav }) => {
    return (
        <>
            {
                isUserAuthenticated() ?
                    <>
                        <Topbar toggleSideNav={toggleSideNav} />
                        <ProtectedRoute>
                            <Pages />
                        </ProtectedRoute>
                    </>
                    :
                    <PublicRoute >
                        <Pages />
                    </PublicRoute>
            }
        </>
    )
}

export default Page