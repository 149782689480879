
import { shortenString } from "@/helpers";
import { getSections, getVersions, getParagraph, getCurriculumData, getAllParagraphs, createSection,getCurriculumList } from "@/services/curriculum.service";
import { createSlice, current } from "@reduxjs/toolkit";
import _ from 'lodash'
const initialState = {
    versions: [],
    sections: [],
    lessons: [],
    selectedVersion: null,
    selectedUnit: [],
    searchedCurriculumItem: {},
    lessonIndex: null,
    lessonIntroduction: '',
    curriculumAddressBar: {
        introduction: 'Introduction',
        section: '',
        unit: '',
        lesson: '',
        component: '',
    },
    curriculumAddressBarObj: {
        introduction: 'Introduction',
        section: '',
        unit: '',
        lesson: '',
        component: '',
    },
    addressBarName: '',
    searchedSelectedLesson: {},
    selectedUnitIndex: {},
    searchedSelectedComponent: {},
    curriculumParagraph: [],
    curriculumLoader: false,
    selectedGradeLevel: '',
    setToInitial: true,
    openWithGradeLevel: {},
    allParagraphs: null,
    curriculumDetails: {},
    isEditCurriculumComponents: false,
    isStatusActive: true,
    curriculumListData:[],
    updateCurriculumList: true,
};
export const curriculumSlice = createSlice({
    name: 'curriculum',
    initialState,
    reducers: {
        selectedVersion: (state, action) => {
            const currentURL = new URL(window.location.href);
            const allState = current(state)
            if (allState?.selectedVersion === null) {
                const url = currentURL.searchParams.get('versionId');
                const currentVersion = _.find(allState?.versions, item => item?.id == url)
                if(currentVersion){
                    state.selectedVersion = currentVersion
                    currentURL.searchParams.set('versionId', url);
                }else{
                    state.selectedVersion = allState?.versions?.[0];
                    currentURL.searchParams.set('versionId', allState?.versions?.[0]?.id);
                }
            }
            else {

                state.selectedVersion = action.payload
                currentURL.searchParams.set('versionId', action.payload?.value);
            }
            window.history.pushState({}, "", currentURL);
        },
        selectedUnit: (state, action) => {
            state.selectedUnit = action.payload
        },
        selectedUnitIndex: (state, action) => {
            state.selectedUnitIndex = action.payload
        },
        searchedCurriculumItem: (state, action) => {
            state.searchedCurriculumItem = action.payload
        },
        setLessonIntroduction: (state, action) => {
            state.lessonIntroduction = action.payload

        },
        setLessonIndex: (state, action) => {
            state.lessonIndex = action.payload
        },
        curriculumAddressBar: (state, action) => {
            const { type, addressBarData } = action.payload;
            let adressBar = null;
            switch (type) {
                case 'component':
                    const componentState = state.curriculumAddressBar
                    adressBar = componentState.section + componentState.unit + componentState.lesson + addressBarData
                    break;
                case 'lesson':
                    const lessonState = state.curriculumAddressBar
                    adressBar = lessonState.section + lessonState.unit + addressBarData
                    break;
                case 'introduction':
                    adressBar = state.curriculumAddressBar.introduction
                    state.curriculumAddressBarObj = {
                        introduction: 'Introduction'
                    }
                    break;
                default:
                    adressBar = state.addressBarName
                    break;
            }
            state.addressBarName = adressBar;
            state.curriculumAddressBar[type] = addressBarData;
            state.curriculumAddressBarObj[type] = addressBarData;
        },
        curriculumStatus: (state, action) => {
           state.isStatusActive = action.payload
        },
        clearCurriculumReducer: (state, action) => {
            return state = initialState
        },
        searchedSelectedLesson: (state, action) => {
            state.searchedSelectedLesson = action.payload;
        },
        searchedSelectedComponent: (state, action) => {
            state.searchedSelectedComponent = action.payload;
        },
        curriculumParagraph: (state, action) => {
            state.curriculumParagraph = action.payload
        },
        selectedGradeLevel: (state, action) => {
            state.selectedGradeLevel = action.payload
        },
        curriculumLoader: (state, action) => {
            state.curriculumLoader = action.payload
        },
        setToInitial: (state, action) => {
            state.setToInitial = action.payload
        },
        openWithGradeLevel: (state, action) => {
            state.openWithGradeLevel = action.payload
        },
        isEditCurriculumComponents: (state, action) => {
            state.isEditCurriculumComponents = action.payload
        },
        updateCurriculum: (state, action) => {
            state.updateCurriculumList = !state.updateCurriculumList
        },
    },
    extraReducers(builder) {
        builder.addCase(getSections.fulfilled, (state, action) => {
            state.sections = action.payload.data
        });
        builder.addCase(getAllParagraphs.fulfilled, (state, action) => {
            state.allParagraphs = action.payload.data
        });
        builder.addCase(getCurriculumData.pending, (state, action) => {
            state.curriculumLoader = true
        });
        builder.addCase(getCurriculumData.fulfilled, (state, action) => {
            state.curriculumLoader = false
        });
        builder.addCase(getCurriculumData.rejected, (state, action) => {
            state.curriculumLoader = false
        });
        builder.addCase(getVersions.pending, (state, action) => {
            state.curriculumLoader = true
        })
        builder.addCase(getVersions.fulfilled, (state, action) => {
            state.versions = action.payload.data;
            state.curriculumDetails = action.payload.curriculum;
            state.curriculumLoader = false
        })
        builder.addCase(getVersions.rejected, (state, action) => {
            state.curriculumLoader = false
        })
        builder.addCase(getParagraph.pending, (state, action) => {
            state.curriculumLoader = true
        });
        builder.addCase(getParagraph.fulfilled, (state, action) => {
            state.curriculumParagraph = action.payload.data;
            state.curriculumLoader = false
        });
        builder.addCase(getCurriculumList.pending, (state, action) => {
            state.curriculumLoader = true
        });
        builder.addCase(getCurriculumList.fulfilled, (state, action) => {
            state.curriculumListData = action.payload.data;
            state.curriculumLoader = false
        });
    }
})

export const { selectedVersion, selectedUnit, setLessonIndex, selectedUnitIndex, curriculumAddressBarObj, curriculumLoader, searchedCurriculumItem, curriculumAddressBar, setLessonIntroduction, clearCurriculumReducer, searchedSelectedLesson, searchedSelectedComponent, curriculumParagraph, selectedGradeLevel, setToInitial, openWithGradeLevel, allParagraphs, curriculumDetails, isEditCurriculumComponents,curriculumStatus,updateCurriculum } = curriculumSlice.actions;
export default curriculumSlice.reducer;