import _ from 'lodash'
import moment from 'moment';
import { Image } from 'react-bootstrap';
export const getAddress = (address) => {
  let res = {
    name: (address && address.name) || '',
    lat: (address && address.geometry && address.geometry.location).lat() || 0,
    lng: (address && address.geometry && address.geometry.location).lng() || 0,
    address: '',
    city: '',
    state: '',
    state_code: '',
    country: '',
    zipcode: '',
  };

  if (address && address.address_components) {
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] === 'street_number') {
          res.address = address.address_components[i].long_name + ' '
        } else if (address.address_components[i].types[j] === 'route') {
          res.address += address.address_components[i].long_name
        } else if (address.address_components[i].types[j] === 'locality') {
          res.city = address.address_components[i].long_name
        } else if (address.address_components[i].types[j] === 'administrative_area_level_1') {
          res.state = address.address_components[i].long_name
          res.state_code = address.address_components[i].short_name
        } else if (address.address_components[i].types[j] === 'country') {
          res.country = address.address_components[i].long_name
        } else if (address.address_components[i].types[j] === 'postal_code') {
          res.zipcode = address.address_components[i].long_name
        }
      }
    }
  }

  return res;
}

export const getEquipmentUsers = (users) => {
  let data = { id: "user_id" }
  let newUsers = users.map((key) => {
    return _.mapKeys(key, (v, keyName) => {
      return keyName in data ? data[keyName] : keyName;
    })
  })
  return newUsers
}

export const getModels = (parentCategories, slug, modelList) => {
  const parentCategoryId = parentCategories?.find((categ) => categ?.slug === slug)?.id;
  let model = modelList?.filter((model) => +model?.equipment_category?.parent_id === parentCategoryId);
  let brands = model?.map((equipment) => equipment?.equipment_brand);
  brands = _.uniqBy(brands, brand => brand?.id);
  return { model, brands }
}

export const getCategory = (parentCategories, slug, equipmentCategoriesList) => {
  const parentCategoryId = parentCategories?.find((categ) => categ?.slug === slug)?.id;
  let model = equipmentCategoriesList?.filter((model) => +model?.parent_id === parentCategoryId);
  let brands = model?.map((equipment) => equipment?.name);
  return {brands,model};
}

export function convertUTCDateToLocalDate(date, offset = -360) {
  var convertdLocalTime = new Date(date);
  var minuteOffset = offset * 60;
  convertdLocalTime.setMinutes(convertdLocalTime.getMinutes() + minuteOffset);
  return convertdLocalTime;
}
export function convertToUTCDate(date, offset = -360) {
  var convertdLocalTime = new Date(date);
  var minuteOffset = offset * 60;
  convertdLocalTime.setMinutes(convertdLocalTime.getMinutes() - minuteOffset);
  return convertdLocalTime;
}

export const convertLocalToUTC = (date, timeZoneName = "UTC") => {
  const dateTime = moment.tz(date, timeZoneName);
  const utcTime = dateTime.utc();
  return utcTime;
};

export const addDaysToDate = (date, days) => {
  const newDate = new Date(date) || moment();
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const getLogo = (url) => {
  const curriculumLogo = "https://www.focusedfitness.net/plugins/page_header/images/ff-logo.png"
  const fiveForLifeLogo = 'https://shcompliancestorageacct.blob.core.windows.net/shccompliancecontainer/uploads/16793022287141.jpg';
  const fabFiveLogo = 'https://shcompliancestorageacct.blob.core.windows.net/shccompliancecontainer/uploads/16793022864531.jpg';
  const healthReadyLogo = 'https://shcompliancestorageacct.blob.core.windows.net/shccompliancecontainer/uploads/16793021747566.png';
  if (url === '/dashboard')
    return <Image alt="logo" src={curriculumLogo} className="curriculum-logo" />
  else if (url === '/fiveForLife')
    return <Image alt="logo" src={fiveForLifeLogo} className="fiveForLife-logo h-100 py-2 ms-2" />
  else if (url === '/fabFiveLogo')
    return <Image alt="logo" src={fabFiveLogo} className="fabFive-logo" />
  else if (url === '/healthReady')
    return <Image alt="logo" src={healthReadyLogo} className="healthReady-logo" />
  else
    return <Image alt="logo" src={curriculumLogo} className="curriculum-logo" />
}
export function getWindowWidth() {
  return window.innerWidth;
}
export const toggleNav = (e) => {
  const windowWidth = getWindowWidth();
  if (!e && windowWidth <= 576) {
    document.addEventListener('DOMContentLoaded', function () {
      document.getElementById('menu_checkbox').checked = false;
    });
  }
  let el = document.getElementById('sideNav')
  e ? el.classList.remove('sideNav', 'isSearchNav') : el.classList.add('sideNav', `${'isSearchNav'}`);
}

export const usePageTitle = (title) => {
  document.title = `${title} | [School Health]`;
};