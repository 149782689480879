import { Button, Spinner } from 'react-bootstrap'
const Pressable = (props) => {
   let { onPress, title, titleStyle, classes, children, name, loading } = props;
   return (
      <Button
         {...props}
         className={classes ? `${name === 'curriculum' ? 'Bg-curriculum' : 'pressable'} ${classes}` : `pressable ${name === 'curriculum' ? 'Bg-curriculum' : 'Bg-primary'}`}
         onClick={loading ? () => { } : onPress}
      >
         {loading && <Spinner className='spinner-border spinner-border-sm m-1' />}
         {!loading && title && <span style={{ fontSize: '14px', ...titleStyle }}>{title}</span>}
         {children}
      </Button>
   )
}
export default Pressable;