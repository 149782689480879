import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getCurriculumList} from './curriculumAction';
import * as config from '../../config';

export const rtkQuery = createApi({
    reducerPath: 'CurriculumApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_URL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if (token) {
                headers.set("Authorization", `Bearer ${JSON.parse(token).accessToken}`);
            }
            return headers;
        }
    }),
    tagTypes: ['Curriculum'],
    endpoints: (builder) => ({
        getCurriculumList: getCurriculumList(builder),
        // getSections: getSections(builder),
        // getVersions: getVersions(builder),
        // getParagraph: getParagraph(builder)
    })
})

export const {
  useGetCurriculumListQuery,
  useGetSectionsQuery,
  useGetVersionsQuery,
  useGetLessonsQuery,
} = rtkQuery;