import { apiRequest, apiRequestBodyParams, apiRequestParams } from './equipment.services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as config from '../config';
import { getQueryParams } from '@/helpers';

export const getFormList = apiRequest("get", 'forms', 'GET_FORM_LIST');
export const removeForm = apiRequestParams("delete", 'forms/delete', 'REMOVE_FORM');
export const createForm = apiRequestBodyParams('post', 'forms/create', 'CREATE_FORM');
export const editFormNames = apiRequestParams('patch', 'forms/update', 'UPDATE_FORM_NAMES');
export const duplicateForm = apiRequest('post', 'form/duplicate', 'DUPLICATE_FORM');
export const getFormDetails = apiRequestParams('get', 'forms/detail', 'GET_FORM_DETAILS');
export const getFormDetailData = apiRequestParams('get', 'forms/data', 'GET_FORM_DETAIL_DATA');

export const exportForm = createAsyncThunk(
    'EXPORT_FORM',
    async (payload) => {
        const { data, formId } = payload
        try {
            const response = await axios.get(`${config.SOCIAL_URL}form-data/export/${formId}?${getQueryParams(data)}`,
                { responseType: 'arraybuffer' });
            return response
        } catch (err) {
            return err.response.data
        }
    }
)