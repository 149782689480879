import React, { useEffect, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import "@/pages/FormMenu/index.css";
import { getFormList, removeForm, duplicateForm } from "@/services";
import { isSuperAdmin, statusData, usePageTitle } from "@/helpers";
import AddressBar from "@/components/Layout/AddressBar";
import { DeleteModal, FilterForm, PaginationComponent } from "@/components";
import {
  DropDown,
  EmptyComponent,
  TextInput,
  ToolTip,
} from "@/components/common";
import { AddEditModal } from "@/components";
const FormMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state) => {
    return {
      formList: state.form.formList,
      parentCategories: state.equipment.parentCategories,
    };
  });
  const { formList, parentCategories } = formData;
  const [states, setStates] = useState({});
  const {
    isFetching,
    callApi,
    isClearable = false,
    deleteModal = false,
    totalItems = 0,
    activeItem = {},
    showMenu,
    limit = 10,
    activePage = 1,
    loading = false,
    name,
    status,
    isDuplicateFormModal = false,
    formName,
    formDetails = {},
    isSubmitting = false,
  } = states;
  useEffect(() => {
    getData(true);
  }, [callApi]);

  useEffect(() => {
    if (status?.status) {
      handleFilter();
    }
  }, [status]);

  usePageTitle("Forms");

  const getData = (value) => {
    setStates((prev) => ({ ...prev, isFetching: value }));
    let payload = {
      limit: limit,
      offset: (activePage - 1) * limit,
      name: name,
      status: status?.status?.toLowerCase() || "",
    };
    dispatch(getFormList(payload))
      .unwrap()
      .then((res) => {
        setStates((prev) => ({
          ...prev,
          totalItems: res?.total || 0,
          isFetching: false,
        }));
      });
  };

  if (!parentCategories?.includes("form")) {
    return <Navigate to="/dashboard" />;
  }

  const handlePageChange = (page) =>
    setStates((prev) => ({
      ...prev,
      activePage: page,
      callApi: !callApi,
    }));

  const handleCopyUrl = (item) => {
    const url = `${process.env.PUBLIC_URL}/forms/${item?.slug}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied!");
      })
      .catch((error) => {
        toast.error("Failed to copy URL to copied!");
      });
  };
  const openFormView = (item) => {
    const url = `${process.env.PUBLIC_URL}/forms/${item?.slug}`;
    window.open(url);
  };
  const toggleDeleteModal = (item) => {
    setStates((prev) => ({
      ...prev,
      deleteModal: item?.id ? true : false,
      loading: false,
      activeItem: item?.id ? item : {},
      showMenu: item?.id ? false : undefined,
    }));
  };

  const handleFilterChange = (e) =>
    setStates((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value) handleFilter();
  };

  const handleFilter = () => {
    if ((name || status?.name) && !isFetching) {
      setStates((prev) => ({
        ...prev,
        activePage: 1,
        isClearable: true,
        callApi: !callApi,
      }));
    }
  };
  const clearFilter = () =>
    setStates((prev) => ({
      ...prev,
      activePage: 1,
      isClearable: false,
      name: "",
      status: {},
      callApi: !callApi,
    }));

  const handleDeleteForm = () => {
    setStates((prev) => ({
      ...prev,
      loading: true,
    }));
    dispatch(removeForm({ id: activeItem?.id }))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          toggleDeleteModal();
          setStates((prev) => ({
            ...prev,
            callApi: !callApi,
            deleteModal: false,
            loading: false,
            activeItem: {},
            showMenu: undefined,
          }));
          toast.success("Form Delete Successfull");
        } else
          setStates((prev) => ({
            ...prev,
            loading: false,
          }));
      });
  };

  const handleEditFormDetail = (item) => {
    navigate(`/forms/add-form?form-id=${item?.id}`);
  };

  const handleNavigateFormDetail = (id) => {
    navigate(`/forms/form-detail?form-id=${id}`);
  };

  const handleDuplicateFormModal = (item) => {
    setStates((prev) => ({
      ...prev,
      isDuplicateFormModal: !isDuplicateFormModal,
      formDetails: item ? item : "",
      formName: "",
      showMenu: item?.id ? false : undefined,
    }));
  };

  const handleChangeDuplicateFormInput = (e) => {
    setStates((prevState) => ({
      ...prevState,
      [e?.target?.name]: e?.target?.value?.trimStart(),
    }));
  };
  const submitDuplicateForm = () => {
    setStates((prev) => ({ ...prev, isSubmitting: true }));
    let payload = {
      form_id: formDetails?.id,
      name: `${formName}`,
    };
    dispatch(duplicateForm(payload))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message || "Successfully duplicated Form.");
          setStates((prev) => ({
            ...prev,
            isDuplicateFormModal: !isDuplicateFormModal,
            isSubmitting: false,
            showMenu: undefined,
          }));
          getData(false);
        } else {
          toast.error(
            res?.message || "An error occurred while duplicating the form."
          );
          setStates((prev) => ({
            ...prev,
            isSubmitting: false,
            showMenu: undefined,
          }));
        }
      });
  };
  return (
    <>
      <AddressBar page={[{ name: "Forms" }]} />
      <FilterForm
        name="Form"
        onFilterClick={() => handleFilter()}
        isClearable={isClearable}
        onClearFilter={() => clearFilter()}
        dropdownButton={
          <div
            onClick={() => navigate("/forms/add-form")}
            className={`pressable cursor-pointer dropdown-btn d-flex align-items-center justify-content-center`}
          >
            Add Form +
          </div>
        }
      >
        <Row className="justify-content-end mx-0 px-0">
          <Col xs={12} lg={4} className="mt-2">
            <TextInput
              style={{ fontWeight: "500" }}
              size="sm"
              placeholder="Search Form"
              name="name"
              value={name}
              maxlength={50}
              onChange={handleFilterChange}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col xs={12} lg={4} className="mt-2">
            <DropDown
              value={status?.name}
              name="status"
              placeholder="Choose Status"
              data={statusData}
              onChange={(e) =>
                setStates((prev) => ({
                  ...prev,
                  [e.target.name]: JSON.parse(e.target.id),
                }))
              }
            />
          </Col>
        </Row>
      </FilterForm>
      <div>
        {!_.isEmpty(formList) && !isFetching ? (
          <div className="table-responsive">
            <Table style={{ overflowX: "auto" }}>
              <thead className="row  p-2 m-0 Bg-fff rounded mt-2 Shadow d-flex flex-wrap">
                <tr className="d-flex flex-wrap">
                  <th className="col-1 itemText C-primary bold text-truncate">
                    ID
                  </th>
                  <th className="col-3 itemText C-primary bold text-truncate">
                    Name
                  </th>
                  <th className="col-3 itemText C-primary bold text-truncate">
                    Description
                  </th>
                  <th className="col-2 itemText C-primary bold text-truncate">
                    Organization
                  </th>
                  <th className="col-1 itemText C-primary bold text-truncate">
                    Status
                  </th>
                  <th className="col-1 itemText C-primary bold text-truncate">
                    Copy URL
                  </th>
                  <th className="col-1 itemText C-primary bold text-truncate text-lg-end">
                    Actions
                  </th>
                </tr>
              </thead>
              {formList?.map((item, index) => {
                let isStatusActive = item?.status?.toLowerCase() === "active";
                return (
                  <tbody className="row d-flex flex-wrap  Bg-fff rounded mt-2 Shadow p-1 m-0">
                    <tr
                      key={index}
                      className="d-flex flex-wrap justify-contemt-center align-items-center"
                    >
                      <td
                        className="itemText C-818188 col-1 cursor-pointer"
                        onClick={() => handleNavigateFormDetail(item?.id)}
                      >
                        {item?.id}
                      </td>
                      <td
                        onClick={
                          isStatusActive ? () => openFormView(item) : null
                        }
                        className={`itemText C-link col-3 ${
                          isStatusActive && "cursor-pointer"
                        } bold text-truncate`}
                      >
                        {item?.name?.length > 40
                          ? item.name.slice(0, 40)
                          : item.name.charAt(0).toUpperCase() +
                            item?.name.slice(1)}
                      </td>
                      <td
                        className="itemText C-818188 col-3 cursor-pointer"
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        onClick={() => handleNavigateFormDetail(item?.id)}
                      >
                        {item?.description?.length > 40
                          ? `${item?.description?.slice(0, 40)}...`
                          : item?.description}
                      </td>
                      <td className="itemText C-818188 col-2 text-truncate">
                        {item?.account?.name || "N/A"}
                      </td>
                      <td
                        className={`itemText C-primary col-1 bold text-truncate ${
                          item?.status.toLowerCase() === "active"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {item?.status?.charAt(0).toUpperCase() +
                          item?.status.slice(1)}
                      </td>
                      <td className="px-4 C-link col-1 cursor-pointer">
                        {isStatusActive ? (
                          <MdContentCopy
                            onClick={() => {
                              handleCopyUrl(item);
                            }}
                          />
                        ) : (
                          <MdContentCopy
                            style={{ opacity: 0.5, cursor: "not-allowed" }}
                          />
                        )}
                      </td>

                      <td className="col-1 d-flex justify-content-center">
                        <div
                          className="d-flex align-items-center p-0 "
                          xs={12}
                          md={12}
                          lg={2}
                        >
                          <div
                            className=""
                            xs={1}
                            sm={1}
                            lg={"auto"}
                            style={{ maxWidth: "10px" }}
                          >
                            <ToolTip
                              show={showMenu}
                              options={[
                                {
                                  name: "Responses",
                                  onClick: () =>
                                    handleNavigateFormDetail(item?.id),
                                },
                                {
                                  name: "Duplicate",
                                  onClick: () => handleDuplicateFormModal(item),
                                },
                                {
                                  name: "Edit",
                                  onClick: () => handleEditFormDetail(item),
                                },
                                {
                                  name: "Remove",
                                  onClick: () => toggleDeleteModal(item),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
            {totalItems > 10 && (
              <PaginationComponent
                isLimit
                limit={limit}
                activePage={activePage}
                totalItemsCount={totalItems}
                handlePageChange={(page) =>
                  !isFetching && handlePageChange(page)
                }
                handleChange={(e, value) =>
                  setStates((prev) => ({
                    ...prev,
                    [e.target.name]: value,
                    activePage: 1,
                    callApi: !callApi,
                  }))
                }
              />
            )}
          </div>
        ) : !formList || isFetching ? (
          <div className="center" style={{ height: window.innerHeight - 250 }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <div
            className="center flex-column align-items-center mt-2"
            style={{ height: "60vh" }}
          >
            <EmptyComponent
              title="Form"
              onAddNew={
                !isClearable
                  ? () => {
                      setStates((prev) => ({
                        ...prev,
                        selectionModal: true,
                      }));
                    }
                  : false
              }
            />
          </div>
        )}
        <DeleteModal
          name={"Form"}
          show={deleteModal}
          isSubmitting={loading}
          closeModal={toggleDeleteModal}
          onClickDelete={handleDeleteForm}
          messageComponent={
            <p className="lable mt-1 text-center C-dark">
              Are you sure you want to remove <b>{"Form"}</b>?
            </p>
          }
        />
      </div>
      <AddEditModal
        show={isDuplicateFormModal}
        type={"Duplicate"}
        name={"Form"}
        isDisableSave={!formName}
        isSubmitting={isSubmitting}
        closeButtonHide
        closeModal={handleDuplicateFormModal}
        label={"school-health"}
        onSubmit={() => submitDuplicateForm()}
      >
        <h3
          className="text-break"
          style={{ fontSize: "20px", textAlign: "center" }}
        >
          {formDetails?.name}
        </h3>
        <TextInput
          as={Col}
          type="text"
          maxlength={100}
          lable={`Form Name *`}
          placeholder={`Enter Form Name`}
          value={formName}
          name="formName"
          onChange={(e) => handleChangeDuplicateFormInput(e)}
        />
      </AddEditModal>
    </>
  );
};

export default FormMenu;
