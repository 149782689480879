import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import Edit from "../../assets/images/edit.png";
import "./topbar.css";
import { isSuperAdmin } from "@/helpers";
const AddressBar = (props) => {
  let { page, right = null, handleEdit } = props;

  const location = window.location.pathname;
  const curriculumPath = location.includes("curriculum");

  return (
    <div
      style={{ height: "44px", fontSize: "16px" }}
      className={`d-flex flex-row align-items-center mb-2 Bg-fff ${
        curriculumPath ? "C-curriculum" : "C-primary"
      } addressbar_container ${
        isSuperAdmin() ? "addressbar_container_admin" : ""
      }`}
    >
      {/*   page = [{ name: "", link: '/' }]     */}
      {page?.map((item, index) => {
        return item.link && page.length - 1 > index ? (
          <>
            <Link
              to={item?.link}
              className={`bold ms-2 ${
                curriculumPath ? "C-curriculum" : "C-primary"
              } linkText d-flex justify-content-start text-break`}
            >
              {item?.name}
            </Link>
            <p className="C-818188 ms-2 d-flex align-items-center">
              {" "}
              <HiOutlineChevronRight size={20} />{" "}
            </p>
          </>
        ) : (
          <Col
            as={Col}
            className={`bold ms-2 ${
              curriculumPath ? "C-curriculum" : "C-primary"
            } linkText d-flex justify-content-start text-break`}
          >
            {item.name}
            {handleEdit && (
              <button className="edit-btn" onClick={() => handleEdit()}>
                <img src={Edit} alt="Icon" className="zoom" />
              </button>
            )}
          </Col>
        );
      })}
      {right && right}
    </div>
  );
};
export default AddressBar;