import React, {
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
  useMemo,
} from "react";
import { Col, Row } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEquipmentCategoriesList,
  getOrganizationList,
  logout,
  welnetLogin,
  welnetToken,
  logoutApi,
} from "@/services";

import {
  getQueryParams,
  isSuperAdmin,
  isDistributor,
  changeDataKeys,
  isManager,
  isServiceTechnician,
  isReviewer,
  isAuthor,
} from "@/helpers";
import _ from "lodash";
import Select from "react-select";
import { BiSearch } from "react-icons/bi";
import { clearDashboardReducer } from "@/reducers/dashboard";
import { changeWebApp } from "@/reducers/auth";

import LogoContainer from "./LogoContainer";
import "./../../../theme/Colors.css";
import "../topbar.css";
import {
  isEditCurriculumComponents,
  selectedVersion,
} from "@/reducers/curriculum";
import CurriculumSearch from "@/pages/curriculum/searchModal";
import axios from "axios";
import { toast } from "react-toastify";
import { getCurriculumList } from "@/services/curriculum.service";
import curriculumTitleIcon from "../../../assets/images/curriculumTitleIcon.png";
const Notification = lazy(() => import("../../Notification"));
const ProfileMenu = lazy(() => import("./ProfileMenu"));

const Topbar = (props) => {
  const dispatch = useDispatch();

  const reducerData = useSelector((state) => {
    return {
      profile: state.auth && state.auth.user,
      isLoggedIn: state.auth && state.auth.isLoggedIn,
      organizationList: state.auth && state.auth.organizationList,
      webApp: state.auth.webApp,
      versions: state.curriculum.versions,
      selectedVersions: state.curriculum.selectedVersion,
      isEditValue: state.curriculum.isEditCurriculumComponents,
      searchedCurriculumItemData: state.curriculum.searchedCurriculumItem,
      parentCategories: state.equipment.parentCategories,
      curriculumListData: state.curriculum.curriculumListData,
    };
  });
  const {
    isLoggedIn,
    profile,
    organizationList,
    webApp,
    versions,
    selectedVersions,
    searchedCurriculumItemData,
    parentCategories,
    isEditValue,
    curriculumListData,
  } = reducerData;
  const checkOrgPresent = organizationList?.find(
    (findItem) => +findItem?.id === +profile?.account?.id
  );
  const user_account_data = Array.isArray(organizationList)
    ? checkOrgPresent
      ? organizationList
      : [...organizationList, profile?.account]
    : [profile?.account];
  const getIsEditItemData = localStorage.getItem("isEdit");
  const [state, setState] = useState({
    width: 0,
    height: 0,
    search: null,
    organizationState: true,
    isEdit: "",
    organizationData: [],
  });
  const isCurriculum = window.location.pathname.includes("curriculum");
  const pathname = window.location.pathname;
  const token = JSON.parse(localStorage.getItem("token"));

  const { curriculumListDataById } = useSelector((state) => state.equipment);
  const getCurriculumListDataSlug = curriculumListDataById?.map(
    (item) => item?.slug
  );
  const filteredData = curriculumListData?.filter((item) =>
    getCurriculumListDataSlug?.includes(item?.slug)
  );
  const mappedFilteredData = filteredData?.map((item) => item?.id);

  useEffect(() => {
    dispatch(getCurriculumList());
  }, []);

  useEffect(() => {
    if (parentCategories?.length) {
      localStorage.setItem("permissions", JSON.stringify(parentCategories));
      if (mappedFilteredData?.length) {
        localStorage.setItem("curriculumItemsId", mappedFilteredData);
      }
    }
  }, [parentCategories?.length, mappedFilteredData?.length]);

  useEffect(() => {
    if (organizationList?.length) {
      setState((prev) => ({ ...prev, organizationData: user_account_data }));
    }
  }, [organizationList?.length]);

  const showSelectBox = useMemo(() => {
    return (
      isSuperAdmin() || isDistributor() || isManager() || isServiceTechnician()
    );
  }, [token]);
  const { isSearch, toggleSideNav } = props;
  const { search, organizationState, isEdit } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const windowUrl = location.search;
  const params = new URLSearchParams(windowUrl);
  const inputRef = useRef(null);
  let switchBtnColor = isEditValue ? "Bg-success" : "Bg-danger";

  useEffect(() => {
    if (getIsEditItemData == "true") {
      setState({ ...state, isEdit: true });
      dispatch(isEditCurriculumComponents(true));
    }
  }, [isEdit, getIsEditItemData]);

  const sizes = {
    searchBox:
      isSuperAdmin() ||
      isDistributor() ||
      isManager() ||
      isServiceTechnician() ||
      isCurriculum
        ? { xs: 2, sm: 3, md: 3, lg: 3, xl: 3, xxl: { offset: 1, span: 3 } }
        : { xs: 5, sm: 4, md: 4, lg: 2, xl: 4, xxl: 4 },
    orgBox:
      isSuperAdmin() ||
      isDistributor() ||
      isManager() ||
      isServiceTechnician() ||
      isCurriculum
        ? {
            xs: { span: 12 },
            sm: 2,
            md: 2,
            lg: 2,
            xl: 1,
            xxl: { offset: 1, span: 2 },
          }
        : {},
    profileBox:
      isSuperAdmin() ||
      isDistributor() ||
      isManager() ||
      isServiceTechnician() ||
      isCurriculum
        ? { xs: 3, sm: { span: 1, order: "last" }, md: 2, lg: 2, xl: 2, xxl: 2 }
        : { xs: 2, sm: 1, md: 3, lg: 2, xl: 2, xxl: 2 },
  };

  const orgSelectStyles = {
    option: (pre, state) => ({
      ...pre,
      backgroundColor: state.isSelected
        ? "var(--blue-color-light)"
        : state.isFocused
        ? "#e9ecef"
        : "",
      color: state.isSelected
        ? "var(--primary-color)"
        : state.isFocused
        ? "#545454"
        : "#232323",
      padding: "8px 0 8px 20px",
      cursor: "pointer",
    }),
    control: () => ({ display: "flex", width: "100%" }),
    groupHeading: (pre) => ({ ...pre, color: "#818188", fontSize: "12px" }),
    singleValue: (pre) => ({ ...pre, color: "var(--primary-color)" }),
  };

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      window.history.pushState(null, null, window.location.href);
      const handlePopState = () => {
        window.history.go(1);
      };
      window.addEventListener("popstate", handlePopState);
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (!window.location.href?.includes("curriculum")) {
      dispatch(changeWebApp("schoolhealth"));
      // localStorage.setItem("isCurriculum", "schoolhealth");
    }
    if (token && pathname?.includes("/curriculum")) {
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      Object.assign(link, {
        type: "image/x-icon",
        rel: "shortcut icon",
        href: curriculumTitleIcon,
      });
      document.head.appendChild(link);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions());
    if (
      (isSuperAdmin() ||
        isDistributor() ||
        isManager() ||
        isServiceTechnician()) &&
      _.isEmpty(organizationList)
    ) {
      dispatch(getOrganizationList())
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState((prev) => ({ ...prev, organizationData: res?.data }));
          }
        });
    }
    // if(isSuperAdmin()){
    //   dispatch(getDistributorList())
    // }

    return () => {
      window.removeEventListener("resize", updateWindowDimensions());
    };
  }, [isServiceTechnician()]);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    dispatch(logoutApi());
    const newWebApp = webApp === "schoolhealth" ? "curriculum" : "schoolhealth";
    dispatch(changeWebApp(newWebApp));
    setTimeout(() => {
      window.location.reload();
    }, 0);
    navigate("/", { replace: true });
  };

  const formatGroupLabel = (data) => (
    <div className="center justify-content-start">
      <span title={data?.label} className="text-truncate">
        {data.label}
      </span>
    </div>
  );

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    if (token?.account?.id && organizationState) {
      setState({ ...state, organizationState: false });
      dispatch(getEquipmentCategoriesList());
    }
  }, [organizationList]);
  useEffect(() => {
    if (searchedCurriculumItemData?.type === "version") {
      const searchedVersion = _.find(versions, function (item) {
        return item.name == searchedCurriculumItemData?.name;
      });
      dispatch(selectedVersion(searchedVersion));
    }
  }, [searchedCurriculumItemData, versions]);
  const getAccountsList = (list) => {
    let data;
    if (list) {
      let labels = [];
      data = list?.flatMap((item, index, array) => {
        if (!labels.includes(item?.distributor_id)) {
          labels.push(item?.distributor_id);
          return {
            label: item?.distributor?.name
              ? item?.distributor?.name
              : item?.name,
            options: changeDataKeys(
              array.filter((i) => i?.distributor_id === item?.distributor_id),
              { name: "label", id: "value" }
            ),
          };
        } else return [];
      });
    }
    return data;
  };

  const getAccountName = () => {
    let data = JSON.parse(localStorage.getItem("token"));
    let item;
    if (data?.account?.name) item = data?.account;
    else item = organizationList && organizationList[0];
    if (item) item = { ...item, label: item?.name };
    if (item) item.label = item?.name ?? "";
    return item || null;
  };

  const changeWeb = async (appName) => {
    switch (appName) {
      case "schoolhealth":
        dispatch(changeWebApp(appName));
        // localStorage.setItem("isCurriculum", "schoolhealth");
        navigate("/dashboard");
        break;
      case "welnet":
        const emailObj = {
          email: profile?.email,
          // email: 'ted.brace@gmail.com',
        };
        try {
          const getToken = await dispatch(
            welnetToken({ data: emailObj })
          ).unwrap();
          if (getToken?.access_token) {
            const welnetToken = {
              accessToken: getToken?.access_token,
            };

            try {
              const getWelnetUrl = await dispatch(
                welnetLogin({ data: welnetToken })
              ).unwrap();

              if (getWelnetUrl?.redirect_uri) {
                window.open(getWelnetUrl?.redirect_uri, "_blank");
              } else {
                toast.error(getWelnetUrl?.message);
              }
            } catch (error) {}
          }
        } catch (error) {}
        break;
      case "curriculum":
        navigate("/curriculum");
        dispatch(changeWebApp(appName));
        // localStorage.setItem("isCurriculum", "curriculum");
        break;
      default:
        dispatch(changeWebApp(appName));
        // localStorage.setItem("isCurriculum", "schoolhealth");
        navigate("/dashboard");
        break;
    }
  };
  const updateWindowDimensions = () =>
    setState({
      ...state,
      width: window.innerWidth,
      height: window.innerHeight,
    });

  const getValue = () => {
    if (location.search.includes("search")) return params.get("search");
    else return "";
  };

  const getOptionsData = () => {
    if (isCurriculum) {
      return changeDataKeys(versions, { name: "label", id: "value" });
    }
    return getAccountsList(state.organizationData);
  };

  const handleAccountSelection = (account) => {
    let acc = { ...account, id: account.value || "1", name: account.label };
    let token = JSON.parse(localStorage.getItem("token"));
    token.account = acc;
    localStorage.setItem("token", JSON.stringify(token));
    clearDashboardReducer();
    navigate("/");
    window.location.reload();
  };

  const getParams = () => {
    let objectParams = { search: search };
    if (location?.search) {
      objectParams = JSON.parse(
        '{"' +
          decodeURI(location?.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      objectParams.search = search;
    }
    return "?" + getQueryParams(objectParams);
  };

  const setVersion = (e) => {
    dispatch(selectedVersion(e));
    const currentURL = new URL(window.location.href);
    const paramName = "grade-level";
    currentURL.searchParams.delete(paramName);
    setTimeout(() => {
      window.history.pushState({}, "", currentURL);
    }, 500);
  };
  const handleEditCurriculum = (e) => {
    setState({ ...state, isEdit: !isEditValue });
    dispatch(isEditCurriculumComponents(!isEditValue));
    localStorage.setItem("isEdit", JSON.stringify(!isEditValue));
  };
  const getVersionName = () => {
    return selectedVersions?.label
      ? { label: selectedVersions?.label, value: selectedVersions?.value }
      : { label: selectedVersions?.name, value: selectedVersions?.id };
  };
  return (
    <>
      <Row className="d-flex flex-row justify-content-between topBar pe-3 Bg-fff m-0">
        <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2} className="h-100">
          <LogoContainer
            changeWeb={changeWeb}
            toggleSideNav={toggleSideNav}
            isSearch={isSearch}
            webApp={webApp}
          />
        </Col>
        {pathname.includes("/curriculum/") &&
        ![
          "/curriculum",
          "/curriculum/profile",
          "/curriculum/change-password",
          "/curriculum/setting",
          "/curriculum/profile/edit",
        ]?.includes(pathname) &&
        versions?.length &&
        (isSuperAdmin() || isReviewer() || isAuthor()) ? (
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="mt-2 d-flex align-items-center justify-content-center"
          >
            <input
              className="switch-checkbox"
              id={`switch`}
              checked={isEditValue}
              type="checkbox"
            />
            <button
              onClick={() => handleEditCurriculum()}
              className={`switch-label`}
              htmlFor={`switch`}
            >
              <span className={`switch-button ${switchBtnColor}`} />
            </button>
            <span className="C-818188 ps-2" style={{ minWidth: "70px" }}>
              {isEditValue ? ` Edit On ` : "Edit Off"}
            </span>
          </Col>
        ) : (
          ""
        )}

        {!isCurriculum ? (
          <Col
            {...sizes.searchBox}
            className={`d-flex align-items-center justify-content-start justify-content-lg-center h-100 me-md-0 ${
              isSuperAdmin() || isServiceTechnician() ? "me-5" : "ms-1"
            } `}
          >
            <div
              className="rounded ps-2 ms-md-0 center justify-content-start justify-content-md-start"
              style={{ height: "43px", width: "100%" }}
            >
              <div
                className="h-100 center ps-2"
                style={{
                  color: "#cdcdcd",
                  background: "#f5f5f5",
                  borderRadius: "6px 0px 0px 6px",
                }}
              >
                <BiSearch size={25} onClick={() => inputRef.current.focus()} />
              </div>
              <input
                type="text"
                placeholder="Search"
                ref={inputRef}
                value={search !== null ? search : getValue()}
                className="border-0 m-0 p-0 ps-2 h-100 searchInput"
                style={{ fontSize: "20px" }}
                onChange={(e) => setState({ ...state, search: e.target.value })}
                onKeyUp={(event) => {
                  if (event.keyCode === 13 && search) {
                    setState({ ...state, search: null });
                    navigate({ pathname: "/search", search: getParams() });
                  }
                }}
              />
            </div>
          </Col>
        ) : (
          <div
            className={`col-1 ${
              pathname.includes("/curriculum/") &&
              ![
                "/curriculum",
                "/curriculum/profile",
                "/curriculum/change-password",
                "/curriculum/setting",
                "/curriculum/profile/edit",
              ]?.includes(pathname) &&
              versions?.length
                ? "col-md-3"
                : "col-md-4"
            }`}
          >
            <Suspense>
              <CurriculumSearch />
            </Suspense>
          </div>
        )}
        <Col
          {...sizes?.profileBox}
          className="d-flex align-items-center justify-content-end h-100 p-md-0"
        >
          <Suspense>
            <div className="me-2 me-md-0">
              {isCurriculum ? "" : <Notification />}
            </div>
            <div>
              <ProfileMenu
                isLoggedIn={isLoggedIn}
                profile={profile}
                handleLogout={handleLogout}
              />
            </div>
          </Suspense>
        </Col>
        {!isCurriculum && showSelectBox ? (
          <Col
            {...sizes?.orgBox}
            className="center h-100 p-sm-0 p-3 Bg-fff"
            id="orgDropdown"
          >
            <Select
              className="d-flex w-100 F-14 rounded border C-primary bg-fff"
              styles={orgSelectStyles}
              value={getAccountName()}
              onChange={(e) => handleAccountSelection(e)}
              options={getOptionsData()}
              formatGroupLabel={formatGroupLabel}
            />
          </Col>
        ) : null}
        {pathname.includes("/curriculum/") &&
        ![
          "/curriculum",
          "/curriculum/profile",
          "/curriculum/change-password",
          "/curriculum/setting",
          "/curriculum/profile/edit",
        ]?.includes(pathname) &&
        versions?.length ? (
          <Col
            {...sizes?.orgBox}
            className="center h-100 bg-white ms-md-0 p-sm-0 p-3"
            id="orgDropdown"
          >
            <Select
              className="d-flex w-100 F-14 rounded border C-curriculum bg-fff"
              styles={orgSelectStyles}
              value={getVersionName()}
              onChange={(e) => setVersion(e)}
              options={getOptionsData()}
              formatGroupLabel={formatGroupLabel}
              placeholder={"select version"}
            />
          </Col>
        ) : null}
      </Row>
      <Outlet />
    </>
  );
};

export default Topbar;
